import classnames from 'classnames'
import Option from './Option'
import ContentComponent from './ContentComponent'

export default function ListOption ({
  data: listData,
  style,
  index,
}) {
  const {
    active,
    checkDragged,
    Content,
    draggable,
    onChange,
    onDragEnd,
    onDragStart,
    onDrop,
    optionClassName,
    options,
    search,
    selectable,
    withCheckboxes,
    multiselect,
  } = listData

  const data = options[index]
  const { disabled } = data

  const currentDragged = checkDragged?.(data)
  const currentDraggable = draggable?.(data)
  const currentSelectable = selectable?.(data) ?? true
  const currentActive = active?.(data)

  return (
    <Option
      className={classnames(optionClassName({
        dragged: currentDragged,
        active: currentActive,
      }), 'select-option-item')}
      style={{
        ...style,
        borderBottom: '1px solid #0a1b31',
      }}
      active={currentActive}
      dragged={currentDragged}
      disabled={disabled}
      draggable={currentDraggable}
      selectable={currentSelectable}
      onDragEnd={onDragEnd ? () => onDragEnd(data) : undefined}
      onDragStart={onDragStart ? () => onDragStart(data) : undefined}
      onDrop={onDrop ? type => onDrop(type, data) : undefined}
      onClick={onChange ? () => onChange(data) : undefined}
    >
      {withCheckboxes ? (
        <input
          className="form-check-input flex-shrink-0 m-0 me-2"
          type="checkbox"
          checked={currentActive || false}
          onChange={() => onChange(data)}
        />
      ) : null}

      <ContentComponent
        Content={Content}
        data={data}
        context={listData}
        active={currentActive}
        dragged={currentDragged}
        search={search}
        draggable={currentDraggable}
        selectable={currentSelectable}
        className={classnames({
          'text-muted': disabled || !currentSelectable,
        })}
      />
    </Option>
  )
}
