import { useState } from 'react'
import cx from 'classnames'
import { useQuery } from '@tanstack/react-query'
import {
  Icon as LegacyIcon, CustomScrollbars, useMobile, withObservables,
} from '@wiz/components'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Icon from '@/shared/icon'
import { dbProvider, Q } from '@wiz/store'
import { wizataApi } from '@/api'
import { useIntl } from '@wiz/intl'
import Cards from './cards'
import s from '../index.module.css'

const DashboardTiles = ({
  sensors, categories, dashboards, ...props
}) => {
  const { contextTwinId } = useGlobalExecute()

  const {
    data: sensorValues, isLoading,
  } = useQuery({
    queryKey: [ 'sensorValues', contextTwinId + props.isComponent ],
    queryFn: () => wizataApi.getSensorStatistics(sensors.map(a => a.hardwareId)),
    enabled: !!sensors?.length,
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  })

  return categories?.map((item) => {
    const children = dashboards[item.labelId]

    return <Accordion {...props} item={item} options={children} sensorValues={sensorValues} />
  })
}

const Accordion = ({
  item, options, onEdit, onDashboardClick, sensorValues,
}) => {
  const [ isExpanded, setExpanded ] = useState(true)

  const intl = useIntl()
  const isMobile = useMobile()

  if (!options) {
    return null
  }
  let isLegacyIcon = true
  if (item.icon?.includes('--')) {
    isLegacyIcon = false
  }
  return (
    <div className={cx({
      'mb-4': isMobile && isExpanded,
    })}
    >
      <div
        key={item.id}
        onClick={() => setExpanded(!isExpanded)}
        className={cx(
          s.tilesDashboardsTitle,
          {
            'px-3 py-2 mb-0': isMobile,
            [s.bgMobile]: isMobile,
          },
        )}
        aria-hidden
      >
        {isLegacyIcon ? <Icon name={item.icon} color={item.color} size="sm" className="me-2" /> : (
          <LegacyIcon
            name={item.icon || 'placeholder'}
            color={item.color}
            size="lg"
            className="me-2"
          />
        )}
        <span className="mt-1 fs-6 text-truncate">
          {intl.t(item.label) || 'Others'}
        </span>
        <Icon
          name={isExpanded ? 'faAngleUp' : 'faAngleDown'}
          className="ms-auto"
        />
      </div>
      <hr
        className={cx(s.hr, {
          [s.hrMobile]: isMobile,
          [s.hrCollapsed]: isMobile && !isExpanded,
        })}
        style={{ backgroundColor: item.color }}
      />
      {isExpanded ? (
        <CustomScrollbars
          horizontal={false}
          shadow={false}
          autoHeight
          autoHeightMax="90%"
        >
          <Cards
            options={options}
            onEdit={onEdit}
            sensorValues={sensorValues}
            onChange={sub => onDashboardClick(sub.twinId, sub, { category: item.labelId, sub: sub.id })}
            isComponent
          />
        </CustomScrollbars>
      ) : null}
    </div>
  )
}

const enhance = withObservables([ 'dashboards' ], ({ dashboards }) => {
  const sensorIds = []

  if (dashboards && Object.values(dashboards)?.length) {
    for (const values of Object.values(dashboards)) {
      if (values?.length) {
        for (const item of values) {
          if (item.insights?.length) {
            for (const insight of item.insights) {
              if (insight?.sensorId) {
                sensorIds.push(insight.sensorId)
              }
            }
          }
        }
      }
    }
  }

  return {
    settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
    sensors: dbProvider.database.collections.get('sensors').query(
      Q.where('id', Q.oneOf(sensorIds)),
    ).observeWithColumns([ 'updated_at' ]),
  }
})

export default enhance(DashboardTiles)
