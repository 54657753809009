import { useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Twin as Twin$ } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import FormDialog from '@/components/Form/FormDialog'
import { useLocation } from '@/router'
import { CorePages } from '@/utils/consts'
import Removed from '../Removed'
import Section from './Section'
import SectionInsights from './SectionInsights'
import Schema from './Schema'
import resolver from './resolver'

export default function Twin ({
  dialog,
  id,
  onClose,
  onSubmit,
  onSuccess,
  insights = [],
  twin,
}) {
  const { contextTwinId } = useGlobalExecute()
  const queryClient = useQueryClient()
  const loc = useLocation()
  const [ ,, corePage ] = loc.pathname.split('/')

  const defaultValues = useMemo(() => ({
    twin: {
      ...Twin$.toJSON(twin),
    },
    insights: {
      list: [
        ...insights.map(insight => ({ ...insight, condition: JSON.parse(insight.condition) })),
      ],
      selected: null,
    },
  }), [
    twin,
    insights,
  ])

  if (id && !twin) {
    return <Removed />
  }

  return (
    <FormDialog
      dataTestid="twinForm"
      defaultValues={defaultValues}
      dialog={dialog}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={(res) => {
        queryClient.invalidateQueries({ queryKey: [ 'insightsListByTwins', contextTwinId ] })
        if (corePage !== CorePages.OPERATE) {
          onSuccess(res)
        }
      }}
      resolver={resolver}
      schema={Schema}
    >
      <Section scope="twin" />
      <SectionInsights scope="insights" />
    </FormDialog>
  )
}
