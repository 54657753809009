import {
  useCallback, useEffect,
} from 'react'
import cx from 'classnames'
import { useIntl } from '@wiz/intl'
import { useMobile } from '@wiz/components'
import TwinSelector from '@/components/TwinSelector'
import { Options } from '@/components/Header/ButtonSettings'
import Icon from '@/shared/icon'
import { useLocation } from '@/router'
import { isEmpty } from '@wiz/utils'
import { useAuth } from '@/auth'
import routes from '@/pages/routes.json'
import { Menu } from './Menu'
import parts from './parts.json' // check json for 'hidden', those are hidden using access
import s from './index.css'

const TopMenu = ({
  className, ...props
}) => {
  const { globalSettings } = props

  const auth = useAuth()
  const isMobile = useMobile()

  const loc = useLocation()
  const [ ,, currentPage ] = loc.pathname.split('/')

  const checkPermission = item => !!(
    isEmpty(item.access) ||
      item.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
  )

  useEffect(() => {
    const env = {
      color: globalSettings.EnvironmentColor,
      logo: globalSettings.EnvironmentLogo,
      name: globalSettings.EnvironmentName,
    }
    const localEnv = {
      color: localStorage.getItem('EnvironmentColor'),
      logo: localStorage.getItem('EnvironmentLogo'),
      name: localStorage.getItem('EnvironmentName'),
    }

    if (env.color !== localEnv.color) {
      localStorage.setItem('EnvironmentColor', env.color)
    } else if (env.logo !== localEnv.logo) {
      localStorage.setItem('EnvironmentLogo', env.logo)
    } else if (env.name !== localEnv.name) {
      localStorage.setItem('EnvironmentName', env.name)
    }
  }, [
    globalSettings.EnvironmentColor,
    globalSettings.EnvironmentName,
    globalSettings.EnvironmentLogo,
  ])

  return (
    <div className={className} role="menu">
      {isMobile ? null : <TwinSelector />}
      <div className={cx('d-flex py-2', {
        'flex-column ps-3': isMobile,
        'ps-4': !isMobile,
      })}
      >
        {parts.map(({
          name, ...rest
        }, idx) => {
          const foundItems = routes.find(route => route.attr.id === rest.attr.id)

          const hasChildren = foundItems.children?.length
          const hasAccess = () => {
            if (rest.access && checkPermission(rest)) {
              return true
            }

            if (hasChildren) {
              return foundItems.children.reduce((acc, val) => {
                if (val.children) {
                  const childrenWithAccess = val.children.filter(checkPermission)

                  if (childrenWithAccess.length) {
                    return true
                  }
                }
                if (checkPermission(val)) {
                  return true
                }
                return false
              }, false)
            }
            return false
          }

          if (!hasAccess()) {
            return null
          }

          return (
            <Menu
              className={cx('fs-6', s.menuItem, {
                'w-100': isMobile,
                [s.active]: currentPage === rest.attr.id,
              })}
              classNameSpan={cx({ 'd-flex flex-fill': isMobile })}
              key={rest.id}
              idx={idx}
              name={name}
              {...rest}
              {...props}
            />
          )
        })}
      </div>
    </div>
  )
}

export { TopMenu }
