export default function insights (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      insights: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/Insights/paged', { ...params })
              return request.fetch()
            },
          },
          refetchBatch: {
            enumerable: true,
            value (params) {
              const request = client.post('/Insights/multiple', { ...params })
              return request.fetch()
            },
          },
          getListByTwins: {
            enumerable: true,
            value (params) {
              const request = client.post('/Insights/multiple/twin-filtered', params)
              return request.fetch()
            },
          },
          getListByComponents: {
            enumerable: true,
            value (params) {
              const request = client.post('/Insights/multiple/component-filtered', params)
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Insights/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/Insights/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/Insights', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/Insights/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
