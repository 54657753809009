import { useEffect, useMemo } from 'react'
import cx from 'classnames'
import Icon from '@/shared/icon'
import { getStatus } from '@/utils/insights'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import s from '../index.module.css'
import useAppContext from '../../../hooks/useAppContext'

const statusMap = [
  {
    id: 3, name: 'Informative', color: '#F6F4FB', code: SEVERITY_ENUM.INFO,
  },
  {
    id: 2, name: 'Success / positive', color: '#7CFFB2', code: SEVERITY_ENUM.SUCCESS, icon: 'faCircleCheck',
  },
  {
    id: 1, name: 'Warning', color: '#FDDD60', code: SEVERITY_ENUM.WARNING, icon: 'faDiamondExclamation',
  },
  {
    id: 0, name: 'Critical / Error', color: '#D71818', code: SEVERITY_ENUM.CRITICAL, icon: 'faTriangleExclamation',
  },
]

const Insight = ({
  name, displayPrecision, type, hardwareId, sensorValues, condition, cardId, className, symbol, sensorValuesLoading,
}) => {
  const { onSeveritiesChange, severitiesMapped } = useAppContext()

  const roundedValue = useMemo(() => {
    const value = sensorValues?.[hardwareId]?.lastValue
    if (!value) {
      return 0
    }
    if (displayPrecision) {
      const precision = 10 ** (displayPrecision || 0)
      return Math.round(value * precision) / precision
    }
    return Math.round(value)
  }, [ displayPrecision, sensorValues, hardwareId ])

  const currentStatus = useMemo(() => {
    const status = getStatus(roundedValue, JSON.parse(condition)?.parsed)
    if (status) {
      const foundStatus = statusMap.find(st => Number(st.id) === Number(status.severity))
      if (foundStatus) {
        if (foundStatus?.id === 3) {
          return null
        }
        return foundStatus
      }
    }
    return null
  }, [ roundedValue, condition ])

  const updateCardsStatus = () => {
    const ids = Object.values(severitiesMapped).reduce((acc, value) => {
      value.forEach((id) => {
        acc.push(id)
      })
      return acc
    }, [])
    if (ids?.includes(cardId)) {
      Object.entries(severitiesMapped).forEach(([ key, val ]) => {
        if (val.includes(cardId)) {
          if (key === currentStatus.code) {
            onSeveritiesChange(currentStatus.code, { [cardId]: false })
            // onSeveritiesChange(key, { [cardId]: false })
          } else {
            onSeveritiesChange(key, { [cardId]: false })
            onSeveritiesChange(currentStatus.code, { [cardId]: true })
          }
        }
      })
    } else {
      onSeveritiesChange(currentStatus.code, { [cardId]: true })
    }
  }

  useEffect(() => {
    if (currentStatus) {
      updateCardsStatus()
    }
  }, [ currentStatus ])

  return (
    <div
      className={cx('d-flex align-items-center', s.insight, className, {
        'fw-bold': !!currentStatus,
        [s[currentStatus?.code]]: !!currentStatus,
        [s.info]: !currentStatus,
      })}
    >
      <div className={cx('flex-fill text-truncate pe-3')}>
        <div className="fs-7">
          {name}
        </div>
        <div className={cx('fs-8 text-truncate')}>
          {hardwareId}
        </div>
      </div>
      <div className={cx('ms-auto position-relative', s.value)} title={`${roundedValue || 0}`}>
        {/* <span className={cx('text-truncate pointer', s.tooltip)}>{roundedValue || 0}</span>
        <span className={s.tooltiptext}>
          {roundedValue || 0}
        </span> */}
        {sensorValuesLoading ? <Icon name="faSpinner" spin /> : (
          <>
            <span className={cx('fs-5')}>
              {roundedValue || 0}
            </span>
            <span className={cx('fs-7')}>
              {(symbol && symbol !== 'Other') ? symbol : null}
            </span>
          </>
        )}
      </div>
      <div className="ms-1">
        {currentStatus ? <Icon name={currentStatus.icon} size="xl" /> : <div style={{ width: '21.59px' }} />}
      </div>
    </div>
  )
}

export default Insight
