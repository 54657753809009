/* eslint-disable react/no-danger */
import classnames from 'classnames'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Link from '@/components/Link'
import { useCallback } from 'react'

export default function CellName ({
  twin,
  filters,
}) {
  const intl = useIntl()
  const { handleTwinChange } = useGlobalExecute()

  const handleClick = useCallback(() => {
    handleTwinChange(twin?.id)
  }, [ twin, handleTwinChange ])

  return (
    <div className="min-w-0">
      <Link
        className={classnames({ 'text-muted': !twin.name })}
        name="twin-items-list"
        query={{ tree: twin.id }}
        onClick={handleClick}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: twin.name ? markSearch(twin.name, filters.search) : intl.t('form.noname'),
          }}
        />
      </Link>

      {twin.description ? (
        <div
          className="font-italic text-muted text-truncate small"
          dangerouslySetInnerHTML={{
            __html: markSearch(twin.description, filters.search),
          }}
        />
      ) : null}
    </div>
  )
}
