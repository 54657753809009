import cx from 'classnames'
import { useQuery } from '@tanstack/react-query'
import {
  withObservables, Icon as LegacyIcon, CustomScrollbars, useMobile,
} from '@wiz/components'
import { useLocationQuery } from '@/router'
import { dbProvider, Q } from '@wiz/store'
import Icon from '@/shared/icon'
import camelCase from 'lodash/camelCase'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAppContext from '@/hooks/useAppContext'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'
import Insight from './insight'
import s from '../index.module.css'

const iconByTwinType = {
  area: { icon: 'fa--warehouse', color: 'twinColorAreas' },
  machine: { icon: 'fa--cogs', color: 'twinColorMachines' },
  equipment: { icon: 'fa--tools', color: 'twinColorEquipment' },
}

const CardView = ({
  options, onChange, isComponent, onEdit, sensors, sensorValues, units, twinSettings,
}) => {
  const { severities } = useAppContext()
  const { contextTwinId } = useGlobalExecute()

  const auth = useAuth()
  const intl = useIntl()
  const query = useLocationQuery()
  const isMobile = useMobile()

  const {
    data, isLoading, isFetching, isRefetching,
  } = useQuery({
    queryKey: [ 'sensorValues', contextTwinId + isComponent ],
    queryFn: () => wizataApi.getSensorStatistics(sensors.map(a => a.hardwareId)),
    enabled: !!sensors?.length && !sensorValues?.length,
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  })

  return options?.map((item) => {
    if (query.search && !item.name.toLowerCase().includes(query.search?.toLowerCase())) {
      return null
    }
    const insights = item.insights?.map((ins) => {
      const sensor = sensors?.find(snsr => snsr.id === ins.sensorId)
      const unit = units?.find(unt => unt.id === sensor?.unitId)
      if (!sensor) {
        return ins
      }
      return {
        ...ins,
        hardwareId: sensor.hardwareId,
        symbol: unit?.symbol,
      }
    })

    const isErrorState = severities.critical?.[item.id]
    const hasInsights = item.insights?.length
    return (
      <div
        key={item.id}
        className={cx('', s.card, {
          'mb-2': isComponent,
          [s.error]: isErrorState,
          [s.cardMobile]: isMobile,
          [s.noData]: isComponent && !hasInsights,
        })}
        onClick={() => onChange(isComponent ? item : item.id)}
        aria-hidden
      >
        {isComponent ? null : (
          <div className={cx(s.cardImg, {
            [s.cardIcon]: !item.image,
          })}
          >
            {!isErrorState && auth.checkAccessManage('Twin') ? (
              <div
                className={s.cardImgAddBtn}
                onClick={(e) => {
                  e.stopPropagation()
                  onEdit({ item, isComponent })
                }}
                aria-hidden
              >
                <Icon
                  name="faCamera"
                  size="md"
                  className="me-1"
                />
                <Icon
                  name="faPlus"
                  size="md"
                />
              </div>
            ) : null}
            {!isErrorState ? (
              item.image ? (
                <img
                  src={item.image}
                  alt={item.name}
                />
              ) : (
                <>
                  {!item.icon && iconByTwinType[item.type] ? (
                    <LegacyIcon
                      name={iconByTwinType[item.type].icon}
                      color={twinSettings[iconByTwinType[item.type].color]}
                      size="3X"
                      className="m-auto"
                    />
                  ) : null}
                  {(item.icon || item.color) && item?.icon?.includes('cus-') ? (
                    <LegacyIcon
                      name={item.icon}
                      color={item.color}
                      size="3X"
                      className="m-auto"
                    />
                  ) : (
                    <Icon
                      name={camelCase(item.icon)}
                      color={item.color}
                      size="3x"
                      className="m-auto"
                    />
                  )}
                </>
              )
            ) :
                <Icon className="m-auto" name="faTriangleExclamation" size="3x" />}
            {/* <div className={cx('card-action-open position-absolute', s.hover)}>Open</div> */}
          </div>
        )}
        <div className={s.cardBody}>
          <div className={cx('fw-bold d-flex align-items-center', s.pCard, {
            [s.cardTitle]: isComponent,
          })}
          >
            {!item.icon && iconByTwinType[item.type] ? (
              <LegacyIcon
                name={iconByTwinType[item.type].icon}
                color={twinSettings[iconByTwinType[item.type].color]}
                size="md"
                className="me-2"
              />
            ) : null}
            {(item.icon || item.color) && item?.icon?.includes('cus-') ? (
              <LegacyIcon
                name={item.icon}
                color={item.color}
                size="md"
                className="me-2"
              />
            ) : (
              <Icon
                name={camelCase(item.icon)}
                color={item.color}
                size="md"
                className="me-2"
                width={18}
              />
            )}
            <span className="text-truncate flex-fill" style={{ maxWidth: '78%' }}>{item.name}</span>
            <div className="ms-auto d-flex align-items-center">
              <Icon
                name="faStar"
                size="md"
                className={s.favIcon}
                width={18}
                color={isComponent ? '#01101D' : '#184980'} // add isFav check when available
              />
              {auth.checkAccessManage(isComponent ? 'SectionComponents' : 'Twin') ? (
                <Icon
                  name="faPenToSquare"
                  className={s.editIcon}
                  size="md"
                  width={11}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (auth.checkAccessManage(isComponent ? 'SectionComponents' : 'Twin')) {
                      onEdit({ item, isComponent })
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
          {insights?.length ? (
            <CustomScrollbars
              className={cx('flex-fill', { 'd-none': !hasInsights })}
              classNameContent={cx('d-flex flex-column', s.pInsight)}
              horizontal={false}
              shadow={false}
            >
              <div>
                {insights.map(({ id, ...rest }) => (
                  <Insight
                    key={id}
                    className="mt-1"
                    sensorValues={sensorValues || data}
                    {...rest}
                    cardId={item.id}
                    sensorValuesLoading={isLoading || isFetching || isRefetching}
                  />
                ))}
              </div>
            </CustomScrollbars>
          ) : (
            <div className={cx('w-auto h-auto mx-auto my-2', {
              'my-auto': !isComponent,
            })}
            >
              <button
                type="button"
                className={cx('btn btn-fill-secondary-alt btn-text me-2', {
                  invisible: !auth.checkAccessManage('SectionComponents'),
                })}
                onClick={(e) => {
                  e.stopPropagation()
                  if (auth.checkAccessManage('SectionComponents')) {
                    onEdit({ item, isComponent })
                  }
                }}
              >
                <Icon name="faAdd" className="me-2" />
                {intl.t('insights.form.actions.create')}
              </button>
            </div>
          )}
          {item.description ? (
            <div className={cx('fs-7', s.description)}>
              {item.description}
            </div>
          ) : null}
        </div>
      </div>
    )
  })
}

const enhance = withObservables([ 'options' ], ({ options }) => {
  const sensorIds = options.reduce((acc, val) => {
    if (val.insights?.length) {
      val.insights.forEach(ins => ins.sensorId && acc.push(ins.sensorId))
    }
    return acc
  }, [])

  return {
    settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
    sensors: dbProvider.database.collections.get('sensors').query(
      Q.where('id', Q.oneOf(sensorIds)),
    ).observeWithColumns([ 'updated_at' ]),
    units: dbProvider.database.collections.get('units').query().observeWithColumns([ 'updated_at' ]),
    twinSettings: dbProvider.observeGlobalSettings([
      'twinColorAreas',
      'twinColorMachines',
      'twinColorEquipment',
    ]),
  }
})

export default enhance(CardView)
