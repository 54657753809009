import { useCallback, useState } from 'react'
import { Icon, Dialog } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TopMenu from '@/containers/TopMenu/enhanceProps'
import Logo from './Logo'
import ButtonNotify from './ButtonNotify'
import ButtonScreen from './ButtonScreen'
import ButtonTheme from './ButtonTheme'

export default function ButtonMenu (props) {
  const intl = useIntl()
  const [ isOpen, setIsOpen ] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const title = (
    <div className="d-flex ms-2 w-100">
      <Logo {...props} className="flex-fill" />
      {/* <ButtonScreen /> */}
      {/* <ButtonNotify /> */}
      {/* <ButtonTheme /> */}
    </div>
  )

  return (
    <>
      <button
        type="button"
        className="btn btn-text btn-flat-secondary"
        title={intl.t('menu.burger.title')}
        onClick={() => setIsOpen(true)}
      >
        <Icon
          name="fa--bars"
          size="lg"
        />
      </button>
      {isOpen ? (
        <Dialog
          classNameBody="p-0"
          dataTestid="burgerMenu"
          headerActions={title}
          stickToTop
          onClose={() => setIsOpen(false)}
          iconStyles={{ closeSize: 'lg', closeClassNames: 'm-auto' }}
        >
          <TopMenu className="px-3 py-0" onClose={handleClose} />
        </Dialog>
      ) : null}
    </>
  )
}
