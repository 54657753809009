import { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import FieldsInsightConditions from './FieldsInsightConditions'
import s from './index.module.css'

export default function SectionInsightConditions ({ scope }) {
  const intl = useIntl()
  const ref = useRef()
  const { watch, setValue } = useFormContext()

  const conditions = watch(`${scope}.condition.parsed`)

  const handleAdd = () => {
    if (conditions?.length) {
      ref.current.insertField?.(conditions.length - 1, {
        type: 'gte',
        value: '',
        // severity: '', FIXME: check existin severities and create new
      })
    } else {
      ref.current.addInitial?.()
    }
  }
  const handleRemove = () => {
    if (conditions?.length) {
      // delete condition if exists
      setValue(`${scope}.condition.parsed`, [], { shouldDirty: true })
    }
  }

  return (
    <FormSection
      title={intl.t('insights.form.fields.conditions')}
      className={s.sectionConditions}
      classNameContent={s.sectionConditionsContent}
      addon={(
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-sm mx-2"
            onClick={handleAdd}
          >
            <Icon name="faPlus" className="me-2" size="md" />
            {intl.t('insights.form.actions.addConditions')}
          </button>
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-sm"
            onClick={handleRemove}
            disabled={!conditions?.length}
          >
            <Icon name="faFilterSlash" className="me-2" size="md" />
            {intl.t('insights.form.actions.deleteConditions')}
          </button>
        </div>
)}
    >
      <FieldsInsightConditions ref={ref} scope={`${scope}.condition.parsed`} />
    </FormSection>
  )
}
