import {
  useMemo, useState, useCallback, useEffect,
} from 'react'
import classnames from 'classnames'
import { useLocation, useRouter } from '@/router'
import { useIntl } from '@wiz/intl'
import FormTemplate from '@/components/Forms/Template'
import FormBusinessLabel from '@/containers/Forms/BusinessLabel'
import FormPipeline from '@/components/Forms/Pipeline'
import FormTrigger from '@/components/Forms/Trigger'
import { useMobile } from '@wiz/components'
import FormStreamJob from '@/containers/Forms/StreamJob'
import FormTwin from '@/containers/Forms/Twin'
import FormProperties from '@/components/Template/forms/properties'
import FormRegistrations from '@/components/Template/forms/registrations'
import FormSensor from '@/containers/Forms/Sensor'
import { CorePages as PAGES, PagesMap } from '@/utils/consts'
import FormExperimentExecute from '@/components/Forms/ExperimentExecute'
import FormExperiment from '@/containers/Forms/Experiment'
import FormUnit from '@/containers/Forms/Unit'
import FormCategory from '@/containers/Forms/Category'
import FormLabel from '@/containers/Forms/Label'
import FormGroupSystem from '@/containers/Forms/GroupSystem'
import FormDashboard from '@/containers/Forms/Dashboard'
import ButtonMenu from '@/components/Header/ButtonMenu'
import ButtonUser from '@/components/Header/ButtonUser'
import Breadcrumb from '@/containers/Header/Breadcrumb'
import Logo from '@/components/Header/Logo'
import TopBar from './components/topbar'
import routes from './routes.json'
import SideNav from './components/sideNav'
import OperateContextProvider from './context/operate'
import AppContextProvider from './context'
import classes from './index.module.css'
import MobileTwinSelector from '../components/TwinSelector/mobile'
import MobileNav from './components/sideNav/mobile'
import MobileFilters from './operate/components/mobileFilters'

const MainWrapper = ({ children }) => {
  const [ editItem, setEditItem ] = useState({})
  const [ width, setWidth ] = useState(true)
  const [ isCollapsed, setCollapsed ] = useState(false) // this is for sidebar
  const [ isSidebarOpen, setSidebarOpen ] = useState(false)
  const [ isFiltersOpen, setFiltersOpen ] = useState(false)

  const intl = useIntl()
  const isMobile = useMobile()
  const router = useRouter()
  const loc = useLocation()
  const [ ,, corePage, currentPage ] = loc.pathname.split('/')

  const handleEdit = (component, isEdit, id, options) => {
    if (!component) {
      setEditItem(null)
    } else {
      setEditItem({
        component, isEdit, id, options,
      })
    }
  }
  const handleCloseDialog = () => {
    setEditItem(null)
  }

  const handleChangeWidth = useCallback((value, persist) => {
    if (persist) {
      setCollapsed(!isCollapsed)
    }
    setWidth(value)
  }, [ isCollapsed ])

  const sideNavList = useMemo(() => {
    if (![ PAGES.DESIGN, PAGES.TWIN ].includes(corePage)) {
      return undefined
    }
    const current = routes.find(route => route.attr.id === corePage)

    if (current) {
      return current.children
    }
    return []
  }, [ corePage ])

  const hasNoSideNav = !Object.values(PAGES).includes(corePage)

  useEffect(() => {
    if (editItem?.component === 'execute' && ![ PagesMap.Pipelines, PagesMap.Experiments ].includes(currentPage)) {
      handleCloseDialog()
    }
  }, [ currentPage ])

  return (
    <OperateContextProvider>
      <AppContextProvider>
        {isMobile ? (
          <div className="d-flex">
            <div className="flex-fill d-flex align-items-center mx-2 min-w-0">
              <Logo />
              <MobileTwinSelector />
            </div>
            <div className="d-flex align-items-center mx-2">
              <ButtonUser />
              <ButtonMenu />
            </div>
          </div>
        ) : null}
        <div
          id="context-container"
          className="d-flex flex-column flex-fill position-relative overflow-hidden"
        >
          <TopBar
            isFiltersOpen={isFiltersOpen}
            onSidebarOpen={() => setSidebarOpen(true)}
            onFiltersOpen={() => setFiltersOpen(true)}
            onAdd={handleEdit}
          />
          <div className={classnames('h-100 position-relative', {
            'd-grid': isMobile,
            [classes.grid]: !isMobile,
            [classes.collapsed]: !width && !hasNoSideNav,
            [classes.noSideNav]: hasNoSideNav,
          })}
          >
            <SideNav list={sideNavList} width={width} isCollapsed={isCollapsed} onChangeWidth={handleChangeWidth} />
            <div id="content-container" className="position-relative d-flex overflow-hidden">
              {children}
            </div>
          </div>
        </div>

        {isSidebarOpen ? <MobileNav onClose={() => setSidebarOpen(false)} /> : null}
        {isFiltersOpen ? <MobileFilters onClose={() => setFiltersOpen(false)} /> : null}

        {/* forms below */}
        {editItem?.component === 'templates' ? (
          <FormTemplate
            id={editItem.id}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('templates.form.fields.title'),
              dataTestid: 'replaceTemplateDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'registrations' ? (
          <FormRegistrations
            id={editItem.id}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('registrations.registration.title'),
              dataTestid: 'replaceRegistrationDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'business_labels' ? (
          <FormBusinessLabel
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('businessLabels.titleCreate'),
              dataTestid: 'replaceBusinessLabelDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'pipelines-list' ? (
          <FormPipeline
          // id={editItem.id}
          // templateId={templateId}
          // pipelines={list.data}
            onClose={handleCloseDialog}
          // onUpdate={list.refetch}
            dialog={{
              title: intl.t('pipelines.form.fields.title'),
              dataTestid: 'replacePipelineDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'triggers' ? (
          <FormTrigger
          // templateId={id}
          // list={triggersList?.data || []}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('triggers.form.fields.title'),
              dataTestid: 'replaceTriggerDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'stream_jobs' ? (
          <FormStreamJob
          // templateId={templateId}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('streamJobs.titleCreate'),
              dataTestid: 'replaceStreamJobDialog',
            }}
          />
        ) : null}
        {/* {editItem?.component === 'stream_jobs' ? (
        <FormAlert
          // id={editId}
          onClose={handleCloseDialog}
          dialog={{
            title: intl.t('alerts.titleCreate'),
            dataTestid: 'replaceAlertDialog',
          }}
        />
      ) : null} */}
        {editItem?.component === 'templateProperties' ? (
          <FormProperties
            id={editItem.id}
          // template={template}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('registrations.properties.title'),
              dataTestid: 'updatePropertiesDialog',
              size: 'sm',
            }}
          />
        ) : null}
        {editItem?.component === 'sensors' ? (
          <FormSensor
            id={editItem.id}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('sensors.titleCreate'),
              dataTestid: 'replaceSensorDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'items' ? (
          <FormTwin
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('twin.titleCreate'),
              dataTestid: 'replaceTwinDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'execute' ? (
          <FormExperimentExecute
            options={editItem?.options}
            onClose={handleCloseDialog}
          // onUpdate={() => queryClient.invalidateQueries({ queryKey: [ 'pipelinesList' ] })}
            dialog={{
              title: intl.t('pipelines.form.fields.titleExecute'),
              dataTestid: 'replaceExecuteDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'experiments' ? (
          <FormExperiment
            id={editItem?.id}
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('experiments.titleUpdate'),
              dataTestid: 'replaceDashboardDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'units' ? (
          <FormUnit
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('units.titleCreate'),
              dataTestid: 'replaceUnitDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'categories' ? (
          <FormCategory
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('categories.titleCreate'),
              dataTestid: 'replaceCategoryDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'labels' ? (
          <FormLabel
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('label.titleCreate'),
              dataTestid: 'replaceLabelDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'group-systems' ? (
          <FormGroupSystem
            onClose={handleCloseDialog}
            dialog={{
              title: intl.t('groupSystem.titleCreate'),
              dataTestid: 'replaceGroupSystemDialog',
            }}
          />
        ) : null}
        {editItem?.component === 'dashboards' ? (
          <FormDashboard
            onClose={handleCloseDialog}
            onSuccess={(data) => {
              router.push({ name: 'dashboard', params: { id: data?.id } })
            }}
            dialog={{
              title: intl.t('dashboards.titleCreate'),
              dataTestid: 'replaceDashboardDialog',
            }}
          />
        ) : null}
      </AppContextProvider>
    </OperateContextProvider>
  )
}

export default MainWrapper
