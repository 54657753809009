import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import { useAuth } from '@/auth'
import { Icon as LegacyIcon, Dialog, FormInputSearch } from '@wiz/components'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useRouter, useLocationQuery, useLocation } from '@/router'
import Link from '@/components/Link'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import useAppContext from '@/hooks/useAppContext'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import classes from '../index.module.css'

const MobileNav = ({ onClose }) => {
  const [ isExpanded, setExpanded ] = useState(true)
  const [ search, setSearch ] = useState()

  const refRightBar = useRef()

  const {
    dashboardsComponentsDeepList, labels, operateFilters, severities, severitiesMapped,
  } = useAppContext()

  const { selectedComponent, contextTwinId } = useGlobalExecute()

  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()
  const loc = useLocation()
  const auth = useAuth()
  const [ ,,, currentPage ] = loc.pathname.split('/')

  const filteredOptions = useMemo(() => dashboardsComponentsDeepList?.data?.filter((item) => {
    let isMatch = true
    if (operateFilters.boardView && operateFilters.boardView !== BOARD_VIEW_ENUM.GLOBAL) {
      if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
        isMatch = item.ownerId === auth.currentUser.id
      } else if (operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL) {
        isMatch = !item.ownerId
      } else {
        isMatch = item.ownerId === auth.currentUser.id || !item.ownerId
      }
    }

    if (Object.values(severities).length && operateFilters.severity && operateFilters.severity !== SEVERITY_ENUM.ALL) {
      isMatch = severitiesMapped[operateFilters.severity]?.includes(item.id)
    }
    return isMatch
  }), [ dashboardsComponentsDeepList?.data, operateFilters, auth.currentUser.id, severitiesMapped, severities ])

  const isOwn = useMemo(() => {
    if (filteredOptions?.length) {
      const twin = filteredOptions?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ filteredOptions, contextTwinId ])

  const categories = useMemo(
    () => filteredOptions?.reduce((acc, val) => {
      if (!contextTwinId || !isOwn) {
        return []
      }
      const label = labels?.find(l => l.id === val.labelId)

      const subCat = acc.find(item => item.labelId === val.labelId)
      const isChild = val?.twinId === contextTwinId

      if (!subCat && isChild) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: label?.icon?.includes('--') ? label?.icon : camelCase(label?.icon),
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ filteredOptions, labels, contextTwinId, isOwn ],
  )

  const dashboards = useMemo(() => filteredOptions?.reduce((acc, val) => {
    const isChild = val?.twinId === contextTwinId
    if (!val.twinId || !isChild) {
      return acc
    }
    const next = { ...val }

    if (search) {
      const match = next.name?.toLowerCase()?.includes(search.toLowerCase())
      if (!match) {
        return acc
      }
    }
    if (!acc[next.labelId]) {
      acc[next.labelId] = [ next ]
    } else {
      acc[next.labelId].push(next)
    }
    return acc
  }, {}), [ filteredOptions, contextTwinId, search ])

  const handleExpanded = useCallback((id) => {
    setExpanded(prev => ({ ...prev, [id]: !isExpanded[id] }))
  }, [ isExpanded ])

  const isValidUuids = useMemo(() => {
    // FIXME: replace with normal uuid validation for all versions

    const splittedCategory = query.category?.split('-')
    const splittedSub = query.sub?.split('-')
    if (splittedCategory && splittedSub) {
      const categoryValidity = splittedCategory[1]?.length === 4 &&
      splittedCategory[2]?.length === 4 && splittedCategory[3]?.length === 4
      const subValidity = splittedSub[1]?.length === 4 && splittedSub[2]?.length === 4 && splittedSub[3]?.length === 4

      return !!categoryValidity && !!subValidity
    }
    return false
  }, [ query.category, query.sub ])

  const SearchButton = (
    <FormInputSearch
      placeholder={intl.t('form.actions.searchPlaceholder')}
      onChange={setSearch}
      value={search}
      timeout={500}
      className="form-control form-control-sm"
    />
  )

  useEffect(() => {
    if (categories && selectedComponent?.category !== query.category) {
      const categoryIds = categories.map(cat => cat.labelId)
      if (query.sub && query.category && selectedComponent?.category) {
        router.push({
          query: {
            ...query,
            category: !categoryIds.includes(selectedComponent?.category) ?
              categoryIds[0] :
              selectedComponent?.category,
            tree: null,
            contextTwinId: null,
          },
        })
      }
    }
  }, [ selectedComponent?.category, categories ])

  useEffect(() => {
    if (isValidUuids) {
      handleExpanded(query.category)
    }
  }, [ query.category ])

  useEffect(() => {
    if (categories?.length) {
      const prepareToExpand = categories.reduce((acc, val) => {
        if (val.labelId) {
          acc[val.labelId] = true
        }
        return acc
      }, {})
      setExpanded(prepareToExpand)
    }
  }, [])

  return (
    <Dialog
      ref={refRightBar}
      classNameBody="p-0 bg-light-alt"
      container="#context-container"
      strategy="absolute"
      backdrop
      // title={intl.t(section.title)}
      dataTestid="MobileSecondaryNavDialog"
      onClose={onClose}
      orient="left"
      headerActions={SearchButton}
    >
      <div className={cx(
        'd-flex flex-column',
        classes.wrapper,
      )}
      >
        <Link
          name="controlpanel"
          query={{
            category: 'search',
          }}
          className={cx(
            'btn btn-secondary-alt py-2 d-flex justify-content-start',
            classes.menuItem,
            classes.menuItemMobile,
            {
              [classes.menuItemActive]: (!query.category || query.category === 'search' || !contextTwinId) &&
                    !currentPage,
            },
          )}
          title={intl.t('form.actions.overview')}
          onClick={onClose}
        >
          <Icon name="faLocationDot" width={11} height={11} className="me-2" />
          <span className="mt-1 fs-6">
            {intl.t('form.actions.overview')}
          </span>
        </Link>
        {categories?.map(({
          id, label, labelId, icon, color,
        }) => {
          let isLegacyIcon = true
          if (icon?.includes('--')) {
            isLegacyIcon = false
          }

          const children = dashboards[labelId]

          if (!children?.length) {
            return null
          }

          return (
            <div key={id}>
              <div
                className={cx(
                  'btn btn-secondary-alt py-2 d-flex justify-content-start border-y',
                  classes.menuItem,
                  classes.menuItemMobile,
                  { [classes.menuItemActive]: isExpanded[labelId] },
                )}
                onClick={() => handleExpanded(labelId)}
                aria-hidden
              >
                {isLegacyIcon ? (
                  <Icon
                    name={icon}
                    color={color}
                    width={11}
                    height={11}
                    className="me-2"
                  />
                ) : (
                  <LegacyIcon
                    name={icon || 'placeholder'}
                    color={color}
                    width={11}
                    height={11}
                    className="me-2"
                  />
                )}
                <span className={cx('fill-flex text-start text-truncate fs-6', classes.semibold)}>
                  {intl.t(label || 'Other')}
                </span>
                {categories?.length ? (
                  <Icon name={isExpanded[labelId] ? 'faAngleUp' : 'faAngleDown'} className="ms-auto" />
                ) : null}
              </div>
              {isExpanded[labelId] ? children.map(({ name, id }) => (
                <Link
                  key={id}
                  name="controlpanel"
                  query={{
                    ...query, contextTwinId, category: labelId, sub: id,
                  }}
                  className={cx(
                    'btn py-2 ps-3 justify-content-start w-100',
                    classes.menuItem,
                    { [classes.childActive]: id === query.sub },
                  )}
                  onClick={onClose}
                  title={name}
                >
                  <LegacyIcon
                    name="placeholder"
                    width={11}
                    height={11}
                    className="me-2"
                  />
                  <span
                    className="mt-1 fs-6 text-truncate"
                    style={{
                      textAlign: 'left',
                      wordBreak: 'break-word',
                    }}
                  >
                    {name}
                  </span>
                </Link>
              )) : null}
            </div>
          )
        })}
        {auth.checkAccessUpdate('Template') ?
          (
            <div
              className={cx(
                'btn btn-secondary-alt py-2 d-flex justify-content-start',
                classes.menuItem,
                classes.menuItemMobile,
                { [classes.menuItemActive]: currentPage === 'de' },
              )}
              onClick={() => {
                router.push({ name: 'de', params: { id: 'reset' } })
                onClose()
              }}
              aria-hidden
            >
              <LegacyIcon name="wiz--menu--data-explorer" width={11} height={11} className="me-2" />
              <span className="mt-1 fs-6">
                {intl.t('menu.title.explorer')}
              </span>
            </div>
          ) : null}
        <Link
          name="logout"
          className={cx(
            'btn btn-secondary-alt py-2 d-flex justify-content-start',
            classes.menuItem,
            classes.menuItemMobile,
          )}
          title={intl.t('user.logout')}
        >
          <Icon
            name="faArrowRightFromBracket"
            width={11}
            height={11}
            className="me-2"
          />
          <span className="mt-1 fs-6">
            {intl.t('user.logout')}
          </span>
        </Link>
      </div>
    </Dialog>
  )
}

export default MobileNav
