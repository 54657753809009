// const types = {
//   between: 'between',
//   eq: '==',
//   gt: '>',
//   gte: '>=',
//   in: 'in',
//   is: 'is',
//   lt: '<',
//   lte: '<=',
//   neq: '!=',
// }

export const getStatus = (value, conditions) => {
  if (!value || !conditions?.length) {
    return undefined
  }
  for (const condition of conditions) {
    const conditionValue = Number(condition.value)
    if (condition.type === 'eq') {
      if (value === conditionValue) {
        return condition
      }
    } else if (condition.type === 'gt') {
      if (value > conditionValue) {
        return condition
      }
    } else if (condition.type === 'gte') {
      if (value >= conditionValue) {
        return condition
      }
    } else if (condition.type === 'lt') {
      if (value < conditionValue) {
        return condition
      }
    } else if (condition.type === 'lte') {
      if (value <= conditionValue) {
        return condition
      }
    } else if (condition.type === 'neq') {
      if (value !== conditionValue) {
        return condition
      }
    } else {
      return { severity: 0 }
    }
  }
  return undefined
}
