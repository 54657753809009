import { useState, useRef, useMemo } from 'react'
import { of } from 'rxjs'
import { useQuery } from '@tanstack/react-query'
import cx from 'classnames'
import {
  Skeleton, withObservables, DropdownMenu, useMobile,
} from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { dbProvider, Q, Twin } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import FormTwin from '@/containers/Forms/Twin'
import FormGrafanaDashboards from '@/components/Forms/GrafanaDashboards'
import useAppContext from '@/hooks/useAppContext'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import Cards from './cards'
import Accordion from './componentsAccordion'
import s from '../index.module.css'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'iframe',
    name: 'Iframe',
    id: 'iframe',
  },
  {
    value: 'streamlit',
    name: 'Streamlit',
    id: 'streamlit',
  },
]

const TilesView = ({
  components, deepList, list, twins,
}) => {
  const [ edit, setEdit ] = useState()
  const refMenu = useRef()
  const refMenuTarget = useRef()

  const {
    handleAdd,
    labels,
    grafanaCheckData,
    handleDashboardCreate,
    dashboardsComponentsDeepList,
    subCategories,
    operateFilters,
    severities,
    severitiesMapped,
  } = useAppContext()

  const {
    contextTwinId,
    handleTwinChange,
    setSelectedComponent,
  } = useGlobalExecute()

  const router = useRouter()
  const query = useLocationQuery()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const isMobile = useMobile()

  const {
    data: insightsListByTwins,
  } = useQuery({
    queryKey: [ 'insightsListByTwins', contextTwinId ],
    queryFn: () => {
      const twinIds = twins.map(twin => twin.id)
      return wizataApi.insights.getListByTwins(twinIds)
    },
    enabled: !!twins?.length,
    retry: false,
    refetchOnWindowFocus: false,
  })
  const {
    data: insightsListByComponents,
  } = useQuery({
    queryKey: [ 'insightsListByComponents', contextTwinId ],
    queryFn: () => {
      const componentIds = components?.data?.map(component => component.id)
      return wizataApi.insights.getListByComponents(componentIds)
    },
    enabled: !!components?.data?.length,
    retry: false,
    refetchOnWindowFocus: false,
  })
  const handleChange = (id) => {
    handleTwinChange(id)
  }

  const handleDashboardClick = (twinId, dashboard, queryOptions) => {
    handleTwinChange(twinId)
    setSelectedComponent(prev => ({ ...prev, category: queryOptions.category, dashboard }))
    router.push({ query: { ...query, ...queryOptions } })
  }

  const options = useMemo(() => {
    const data = components?.data?.reduce((acc, val) => {
      const subCat = acc.find(item => item.twinId === val.twinId)
      if (!subCat) {
        acc.push(val)
      }

      return acc
    }, [])
    if (data?.length) {
      const twinIds = data.map(cmp => cmp.twinId).filter(i => !!i && i !== contextTwinId)
      if (!contextTwinId) {
        return twins.map((twin) => {
          const _twin = Twin.toJSON(twin)

          if (insightsListByTwins?.length) {
            const foundInsights = insightsListByTwins.filter(insight => insight.twinId === twin.id)
            _twin.insights = foundInsights
          }
          return _twin
        })
      }

      return twins?.filter((twin) => {
        const foundTwin = twinIds.includes(twin.id)
        if (!foundTwin) {
          return false
        }

        // let isMatch = true
        // if (operateFilters.boardView && operateFilters.boardView !== BOARD_VIEW_ENUM.GLOBAL) {
        //   if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
        //     isMatch = twin.ownerId === currentUser.id
        //   } else if (operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL) {
        //     isMatch = !twin.ownerId
        //   } else {
        //     isMatch = twin.ownerId === currentUser.id || !twin.ownerId
        //   }
        // }

        // if (Object.values(severities).length &&
        // operateFilters.severity &&
        // operateFilters.severity !== SEVERITY_ENUM.ALL) {
        //   isMatch = severitiesMapped[operateFilters.severity]?.includes(twin.id)
        // }
        // if (!isMatch) {
        //   return false
        // }

        if (query.search) {
          const match = twin.name?.toLowerCase()?.includes(query.search.toLowerCase())
          if (!match) {
            return false
          }
        }

        return foundTwin
      }).map((twin) => {
        const _twin = Twin.toJSON(twin)

        if (insightsListByTwins?.length) {
          const foundInsights = insightsListByTwins.filter(insight => insight.twinId === twin.id)
          _twin.insights = foundInsights
        }
        return _twin
      })
    }

    return []
  }, [
    components,
    contextTwinId,
    twins,
    query.search,
    insightsListByTwins,
    // severities,
    // severitiesMapped,
    // operateFilters,
  ])

  const filteredOptions = useMemo(() => components?.data?.filter((item) => {
    let isMatch = true
    if (operateFilters.boardView && operateFilters.boardView !== BOARD_VIEW_ENUM.GLOBAL) {
      if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
        isMatch = item.ownerId === currentUser.id
      } else if (operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL) {
        isMatch = !item.ownerId
      } else {
        isMatch = item.ownerId === currentUser.id || !item.ownerId
      }
    }

    if (Object.values(severities).length && operateFilters.severity && operateFilters.severity !== SEVERITY_ENUM.ALL) {
      isMatch = severitiesMapped[operateFilters.severity]?.includes(item.id)
    }
    return isMatch
  }), [ components?.data, operateFilters, currentUser.id, severitiesMapped, severities ])

  const categories = useMemo(
    () => filteredOptions?.reduce((acc, val) => {
      const label = labels?.find(l => l.id === val.labelId)

      const cat = acc.find(item => item.labelId === val.labelId)
      const isChild = val?.twinId === contextTwinId

      if (!cat && isChild) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: label?.icon,
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ filteredOptions, labels, contextTwinId ],
  )

  const dashboards = useMemo(() => filteredOptions?.reduce((acc, val) => {
    const isChild = val?.twinId === contextTwinId
    if (!val.twinId || !isChild) {
      return acc
    }
    const next = { ...val }

    if (insightsListByComponents?.length) {
      const foundInsights = insightsListByComponents.filter(insight => insight.componentId === val.id)
      next.insights = foundInsights
    }
    if (query.search) {
      const match = next.name?.toLowerCase()?.includes(query.search.toLowerCase())
      if (!match) {
        return acc
      }
    }
    if (!acc[next.labelId]) {
      acc[next.labelId] = [ next ]
    } else {
      acc[next.labelId].push(next)
    }
    return acc
  }, {}), [ filteredOptions, contextTwinId, query.search, insightsListByComponents ])

  const isLoading = (deepList.isLoading && deepList.isFetching) || (list.isLoading && list.isFetching)
  return (
    <div className={cx('d-flex flex-column min-h-0', s.root)}>
      <div className={cx('rounded', {
        [s.assetsWrapper]: !isMobile,
        [s.mobileWrapper]: isMobile,
      })}
      >
        {isMobile ? null : (
          <div className="d-flex">
            <div className="mb-2 fs-5 fw-bold">{intl.t('menu.title.assets')}</div>
          </div>
        )}

        <div className={s.cards}>
          {isLoading ?
            Array.from(new Array(5)).map((_, idx) => (
              <Skeleton
                key={idx}
                width="auto"
                minWidth={350}
                height={180}
              />
            )) : options?.length ? (
              <Cards
                options={options}
                onChange={handleChange}
                onEdit={setEdit}
              />
            ) : intl.t('errors.noDataDisplay')}
        </div>
      </div>

      <div className={cx(s.tilesDashboardsGrid, {
        'mx-5': !isMobile,
        [s.tilesDashboardsGridMobile]: isMobile,
      })}
      >
        {contextTwinId ? (
          <Accordion
            categories={categories}
            dashboards={dashboards}
            onDashboardClick={handleDashboardClick}
            onEdit={setEdit}
          />
        ) : null}
      </div>

      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        placement="bottom-start"
        draggable
        autoclose
        options={dashboardTypes
          .filter(dashboardType => (grafanaCheckData?.isSuccess ? dashboardType : dashboardType.id !== 'grafana'))}
        onClick={handleAdd}
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      />
      {edit && !edit.isComponent ? (
        <FormTwin
          id={edit.item.id}
          onClose={() => setEdit(null)}
          dialog={{
            title: intl.t('twin.titleUpdate'),
            dataTestid: 'replaceTwinDialog',
          }}
          insights={edit.item.insights}
          withNavigate={false}
        />
      ) : null}
      {edit?.isComponent ? (
        <FormGrafanaDashboards
          scope={edit.item.type.toLowerCase()}
          item={edit.item}
          onClose={() => setEdit(null)}
          items={subCategories}
          onSuccess={handleDashboardCreate}
          onUpdate={dashboardsComponentsDeepList.refetch}
          insights={edit.item.insights}
          dialog={{
            title: intl.t(`components.form.edit.${edit.item.type.toLowerCase()}`),
            dataTestid: 'createComponentDialog',
          }}
        />
      ) :
        null}
    </div>
  )
}

const enhanceProps = withObservables([ 'components' ], ({ components }) => {
  const twinIds = components?.data?.map(cmp => cmp.twinId).filter(t => !!t)

  const query = twinIds?.length ? dbProvider.database.collections
    .get('twins')
    .query(Q.where('id', Q.oneOf(twinIds)))
    .observeWithColumns([ 'updated_at' ]) : of([])

  return {
    twins: query,
  }
})

export default enhanceProps(TilesView)
