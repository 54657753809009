import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Dropdown,
  withObservables,
  Icon as OldIcon,
  useMobile,
} from '@wiz/components'
import Link from '@/components/Link'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import TwinSelectorTree from '@/hoc/TwinSelectorTree'
import { dbProvider } from '@wiz/store'
import Icon from '@/shared/icon'
import { useLocationQuery, useRouter } from '@/router'
import TwinList from './TwinList'
import s from './index.module.css'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([ 'EnvironmentName' ]),
}))

const twinProps = {
  id: '',
  name: '',
  icon: null,
  prevId: '',
  nextId: '',
  parentId: '',
}

const LeftBar = forwardRef(({
  onClick,
  refDropdown,
  ...props
}, ref) => (
  <Dropdown
    ref={refDropdown}
    arrow
    autoclose
    target={ref}
    width={400}
    placement="bottom"
    className={cx(s.dialog, 'p-3 text-break')}
  >
    <TwinList
      onClick={onClick}
      refDropdown={refDropdown}
      {...props}
    />
  </Dropdown>
))

const MobleTwinSelector = ({
  handleTwinChoose, classNameSpan, loading, settings, options, twinsChain, ...props
}) => {
  const [ rootTwin, setRootTwin ] = useState(twinProps)
  const [ currentTwin, setCurrentTwin ] = useState({
    ...twinProps,
    name: settings.EnvironmentName,
  })

  const {
    contextTwinId, handleTwinChange, isOpenTwinSelector, setIsOpenTwinSelector,
  } = useGlobalExecute()

  const ref = useRef()
  const refDropdown = useRef()

  const router = useRouter()
  const query = useLocationQuery()
  const isMobile = useMobile()

  const handlePrevClick = useCallback(() => {
    handleTwinChange(currentTwin?.prevId)
  }, [ currentTwin?.prevId, handleTwinChange ])

  const handleNextClick = useCallback(() => {
    handleTwinChange(currentTwin?.nextId)
  }, [ currentTwin?.nextId, handleTwinChange ])

  const handleRootClick = useCallback(() => {
    handleTwinChange(currentTwin?.parentId)
  }, [ currentTwin?.parentId, handleTwinChange ])

  const handleClick = useCallback((data) => {
    setCurrentTwin({
      ...currentTwin,
      id: data.recordId,
      name: data.name,
      icon: data.payload?.icon,
      prevId: data?.prevId,
      nextId: data?.nextId,
      parentId: data.payload?.parentId,
    })
    handleTwinChange(data.recordId)

    refDropdown.current?.close()
    setIsOpenTwinSelector(false)
  }, [ handleTwinChange, currentTwin, setIsOpenTwinSelector ])

  const findCurrentTwin = (data, contextId) => {
    if (!Array.isArray(data) || data.length === 0) return

    function findElement (elems) {
      for (let i = 0; i < elems?.length; i++) {
        if (contextId && elems[i].id === contextId) {
          setCurrentTwin({ ...elems[i], icon: elems[i].payload?.icon })
        } else {
          findElement(elems[i].children)
        }
      }
    }
    findElement(data)
  }

  const initTwins = () => {
    if (contextTwinId) {
      if (!currentTwin?.parentId) {
        setRootTwin(twinProps)
        return
      }
      if (twinsChain.length > 0) {
        const startRootTwin = twinsChain.find(twin => twin.id === currentTwin?.parentId)
        setRootTwin(startRootTwin)
      }
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpenTwinSelector(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ ref ])

  useEffect(() => {
    if (isOpenTwinSelector) {
      refDropdown.current?.open()
    }
  }, [ isOpenTwinSelector ])

  useEffect(() => {
    if (contextTwinId) {
      findCurrentTwin(options, contextTwinId)
    }
    initTwins()
  }, [ contextTwinId, currentTwin?.parentId, options ])

  return (
    <div className={cx(
      s.twinControlsBar,
      {
        [s.twinControlsBarSelected]: contextTwinId,
        [s.twinControlsBarMobile]: isMobile,
      },
    )}
    >
      {contextTwinId ? (
        <>
          <button
            type="button"
            className="btn btn-fill-secondary-alt d-block"
            onClick={handleRootClick}
          >
            <Icon name="faAnglesUp" />
          </button>

          {currentTwin?.prevId ? (
            <Link
              name="controlpanel"
              query={{
                contextTwinId: currentTwin?.prevId,
              }}
              className={cx('btn btn-flat-secondary', {
                [s.disabled]: !currentTwin?.prevId,
              })}
              disabled={!currentTwin?.prevId}
              onClick={currentTwin?.prevId && handlePrevClick}
            >
              <Icon name="faChevronLeft" size="sm" />
            </Link>
          ) : (
            <button
              type="button"
              className={cx('btn btn-flat-secondary')}
              disabled={!currentTwin?.prevId}
              onClick={handlePrevClick}
            >
              <Icon name="faChevronLeft" size="sm" />
            </button>
          )}
        </>
      ) : null }
      <div
        ref={ref}
        className={cx(
          'position-relative pointer d-flex align-items-center w-auto justify-content-center m-0 fs-6',
          s.twinName,
          { [s.twinNameSelectedMobile]: contextTwinId || isMobile },
        )}
        aria-hidden="true"
      >
        {contextTwinId && currentTwin.icon ?
          (currentTwin.icon?.includes('cus-') ? (
            <OldIcon
              name={currentTwin.icon}
              color={currentTwin.icon?.color}
              className="me-1"
            />
          ) : (
            <Icon
              name={camelCase(currentTwin.icon)}
              color={currentTwin.icon?.color}
              className="me-1"
            />
          )
          ) : null }

        <span
          className={cx('text-truncate', classNameSpan)}
          style={{ maxWidth: '162px' }}
        >
          {contextTwinId ? currentTwin.name : settings.EnvironmentName}
        </span>
        {(contextTwinId || isMobile) ?
          <Icon className={cx(s.twinBtnIcon)} name="faBarsStaggered" size="sm" /> :
          null}
      </div>
      {contextTwinId ? currentTwin?.nextId ? (
        <Link
          name="controlpanel"
          query={{
            contextTwinId: currentTwin?.nextId,
          }}
          className={cx(s.nextTwinBtn, 'btn btn-flat-secondary', {
            [s.disabled]: !currentTwin?.nextId,
          })}
          onClick={currentTwin?.nextId && handleNextClick}
        >
          <Icon name="faChevronRight" size="sm" />
        </Link>
      ) : (
        <button
          type="button"
          className={cx(s.nextTwinBtn, 'btn btn-flat-secondary')}
          disabled={!currentTwin?.nextId}
          onClick={handleNextClick}
        >
          <Icon name="faChevronRight" size="sm" />
        </button>
      ) : null}
      <LeftBar
        ref={ref}
        refDropdown={refDropdown}
        scrollTo={currentTwin?.id}
        {...props}
        onClick={handleClick}
      />
    </div>
  )
}

export default enhanceSettings(TwinSelectorTree(MobleTwinSelector))
