import { useMobile } from '@wiz/components'
import {
  useRouter,
} from '@/router'
import { useIntl } from '@wiz/intl'
import MobileMenu from './MobileMenu'

const Menu = (props) => {
  const intl = useIntl()
  const isMobile = useMobile()
  const router = useRouter()

  const {
    className, id, idx, name, items, route, classNameSpan, attr,
  } = props

  if (isMobile) {
    return <MobileMenu {...props} items={items} />
  }
  return (
    <div
      id={attr.id}
      className={className}
      key={id}
      tabIndex={idx}
      aria-hidden
      onClick={() => router.push({ name: route.name })}
    >
      <span className={classNameSpan}>{intl.t(name)}</span>
    </div>
  )
}

export { Menu }
