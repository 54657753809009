import cx from 'classnames'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import { MenuItem } from './MenuItem'

const MobileMenu = ({
  className, id, idx, name, items, onAction, classNameSpan, openMenu, handleMenuOpen, ...props
}) => {
  const intl = useIntl()
  const router = useRouter()

  const handleNavigation = () => {

  }

  const active = id === openMenu

  return (
    <>
      <div
        className={cx(className, 'fs-5 d-flex mb-2')}
        key={id}
        role="menu"
        tabIndex={idx}
        aria-hidden
        onClick={() => router.push({ name })}
      >
        <span className={classNameSpan}>{intl.t(name)}</span>
        <Icon name={active ? 'fa--caret-down' : 'fa--caret-right'} className="ms-1" />
      </div>
      {active && items?.length ? items?.map(({ name, ...rest }, idx) => (
        <MenuItem
          key={rest.id}
          idx={idx}
          className={cx('d-flex align-items-center')}
          {...props}
          {...rest}
          onAction={() => handleNavigation()}
        >
          <span className="d-flex flex-fill" role="menuitem">{intl.t(name)}</span>
          {/* {rest.actions?.length ? <Icon name="fa--caret-right" className="ms-4" /> : null} */}
        </MenuItem>
      )) : null}
    </>
  )
}

export default MobileMenu
