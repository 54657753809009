import { useContext } from 'react'
import { useIntl } from '@wiz/intl'
import SelectTemplate from '@/components/Form/ListTemplates'
import ListFilters from '@/components/Form/ListFilters'
import { AppContext } from '@/pages/context'
import useAppContext from '@/hooks/useAppContext'
// import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

export default function Filters ({
  exclude,
}) {
  const { filters, onFilter } = useAppContext()
  // const { contextTwinId } = useGlobalExecute()
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          {/* <div className={classnames('px-md-2 mb-2', !isMobil && 'col-md-3')}>
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={search => onFilter({ search })}
            />
          </div> */}

          {/* <div className={classnames('px-md-2 mb-2', !isMobil && 'col-md-4 col-lg-3')}>
            <SelectTwin
              displayHardware
              placeholder={intl.t('form.fields.twinPlaceholder')}
              value={filters.twinId || contextTwinId}
              onChange={twinId => onFilter({ twinId })}
            />
          </div> */}

          <div className="mb-2">
            <SelectTemplate
              placeholder={intl.t('form.fields.templatePlaceholder')}
              value={filters.templateId}
              onChange={templateId => onFilter({ templateId })}
              className="form-select-sm"
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
