import {
  useMemo, useRef, useCallback, useState,
} from 'react'
import classnames from 'classnames'
import { of as of$ } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import {
  FormSelectDateTimeRange,
  DropdownMenu,
  FormInputSearch,
  withObservables,
  useMobile,
  FormCheckButton,
  Icon as LegacyIcon,
} from '@wiz/components'
import { useAuth } from '@/auth'
import FormatDateTime from '@/containers/FormatDateTime'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import { useIntl } from '@wiz/intl'
import { dbProvider, Q } from '@wiz/store'
import { useRouter, useLocationQuery } from '@/router'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import Breadcrumb from '@/components/Twin/Graph/Breadcrumb'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import TabItems from './breadcrumbs'
import classes from './index.module.css'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'iframe',
    name: 'Iframe',
    id: 'iframe',
  },
  {
    value: 'streamlit',
    name: 'Streamlit',
    id: 'streamlit',
  },
]

const Topbar = ({
  twinsChain, globalSettings, onSidebarOpen, onFiltersOpen,
}) => {
  const [ openFilters, setOpenFitlers ] = useState(false)
  const [ isTouched, setIsTouched ] = useState(false)
  const [ userValue, setUserValue ] = useState([ null, null ])

  const refMenu = useRef()
  const refMenuTarget = useRef()
  const {
    dashboard,
    handleSaveFormData,
    formData,
    isEditing,
    isDuplicating,
    handleAdd,
    handleEdit,
    handleEditId,
    handleDelete,
    handleDuplicate,
    dashboardsComponentsDeepList,
    grafanaCheckData,
    labels,
    operateFilters,
    onOperateFiltersChange,
    onOperateFiltersClear,
  } = useAppContext()

  const { handleTwinChange, contextTwinId } = useGlobalExecute()

  const intl = useIntl()
  const router = useRouter()
  const { isReadOnly } = useAuthComponents()
  const { view, ...query } = useLocationQuery()
  const { currentUser } = useAuth()
  const isMobile = useMobile()

  const defaultValues = [ dashboard?.defaultFrom, dashboard?.defaultTo ]

  const isOwn = useMemo(() => {
    if (dashboardsComponentsDeepList?.data?.length) {
      const twin = dashboardsComponentsDeepList?.data?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ dashboardsComponentsDeepList, contextTwinId ])

  const subCategories = useMemo(
    () => {
      const nextDashboards = dashboardsComponentsDeepList?.data?.filter((item) => {
        if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
          return item.ownerId === currentUser.id
        } if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
          return !item.ownerId
        }
        return item.ownerId === currentUser.id || !item.ownerId
      })

      return nextDashboards?.reduce((acc, val) => {
        const isChild = val?.twinId === contextTwinId

        if (!contextTwinId || !isOwn || !isChild) {
          return acc
        }
        const subCat = acc.find(item => item.id === val.id)
        if (!subCat && val.labelId === query.category) {
          acc.push({ ...val, label: val.name, value: val.id })
        }

        return acc
      }, []).sort((a, b) => a.order - b.order) || []
    },
    [ dashboardsComponentsDeepList?.data, query.category, contextTwinId, isOwn, operateFilters, currentUser?.id ],
  )

  const handleView = useCallback((treeId) => {
    handleTwinChange(treeId)
  }, [ handleTwinChange ])

  const handleRootTwin = useCallback(() => {
    handleView(null)
  }, [ handleView ])

  const isValidUuids = useMemo(() => {
    // FIXME: replace with normal uuid validation for all versions

    const splittedCategory = query.category?.split('-')
    const splittedSub = query.sub?.split('-')
    if (splittedCategory && splittedSub) {
      const categoryValidity = splittedCategory[1]?.length === 4 &&
      splittedCategory[2]?.length === 4 && splittedCategory[3]?.length === 4
      const subValidity = splittedSub[1]?.length === 4 && splittedSub[2]?.length === 4 && splittedSub[3]?.length === 4

      return !!categoryValidity && !!subValidity
    }
    return false
  }, [ query.category, query.sub ])

  const mobileTitle = useMemo(() => {
    if (!query.category) {
      return intl.t('t/menu.title.controlPanel')
    }
    if (query.category === 'search' || !contextTwinId) {
      return intl.t('t/form.actions.overview')
    }

    const label = labels?.find(lbl => lbl.id === query.category)
    if (!label) {
      return ''
    }
    return label.name || ''
  }, [ labels, intl, query.category, contextTwinId ])

  const hasActiveFilters = !!Object.values(operateFilters).length

  if (isMobile) {
    return (
      <div className="d-flex align-items-center w-100">
        <button
          type="button"
          className="btn btn-flat-secondary"
          onClick={onSidebarOpen}
        >
          <Icon name="faBars" size="lg" />
        </button>

        <div className="mx-auto fw-bold fs-6">{mobileTitle}</div>

        <button
          type="button"
          className="btn btn-flat-secondary"
          onClick={onFiltersOpen}
        >
          <Icon name="faSlidersUp" size="lg" />
        </button>
      </div>
    )
  }

  return (
    <>
      <TabItems
        options={subCategories}
        onEditing={handleEdit}
        isEdit={isEditing}
        isLoading={isDuplicating}
        onComponentEdit={handleEditId}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
        // className="d-flex flex-fill"
      >
        {(isMobile || isReadOnly) ? null : (
          <button
            ref={refMenuTarget}
            type="button"
            className={classnames('btn btn-fill-secondary-alt btn-text me-2', {
              'm-auto ms-2': subCategories?.length,
            })}
            style={{ marginTop: '2px !important' }}
            disabled={!contextTwinId}
          >
            <Icon name="faAdd" className="me-2" />
            {intl.t('form.actions.add')}
          </button>
        )}
        {(isMobile || isValidUuids) ? null : (
          <Breadcrumb
            className="flex-fill px-2 py-1"
            options={twinsChain}
            onChange={handleView}
            onRootTwinOpen={handleRootTwin}
            envName={globalSettings.EnvironmentName}
          />
        )}
        {isEditing ? (
          <button
            type="button"
            className={classnames('btn btn-flat-secondary btn-text', classes.editBtn)}
            onClick={() => handleEdit(false)}
            title="Stop editing"
          >
            <Icon type="solid" name="faSquare" />
          </button>
        ) : null}
      </TabItems>

      {(isMobile || subCategories?.length) ? null : (
        <div className="ms-auto ps-2">
          <FormInputSearch
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={(value) => {
              router.replace({ query: { ...query, search: value } })
            }}
            timeout={500}
            className="form-control-sm bg-light-alt"
          />
        </div>
      )}
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text ms-2"
        onClick={onOperateFiltersClear}
        disabled={!hasActiveFilters}
      >
        <Icon name="faFilterSlash" className="me-2" />
        {intl.t('form.actions.clear')}
      </button>
      <button
        type="button"
        className={classnames('btn btn-fill-secondary-alt btn-text ms-2', { acive: openFilters })}
        onClick={() => setOpenFitlers(!openFilters)}
      >
        <Icon name="faFilter" className="me-2" />
        {intl.t('form.actions.filters')}
        {hasActiveFilters ? <Icon name="faCircleDot" className={classes.status} /> : null}
      </button>
      {!(isMobile || isReadOnly) && openFilters ? (
        <div className={classes.dialog}>
          <div className="d-flex mb-2">
            <div className="flex-fill fw-bold">{intl.t('Filter by')}</div>
            <Icon name="faXmark" onClick={() => setOpenFitlers(false)} className="pointer" />
          </div>
          {dashboard?.type !== 'Dashboard' && !(!!formData.stepRequest || !subCategories?.length) ? (
            <>
              <span className="fill-flex text-start text-truncate mb-1">
                {intl.t('t/enum.inputType.sensorInterval')}
              </span>
              <FormSelectDateTimeRange
                value={(
              formData.dateFrom && formData.dateTo ?
                [ formData.dateFrom, formData.dateTo ] :
                [ null, null ]
            )}
                DateTimeComponent={FormatDateTime}
                size="sm"
                clearable
                showArrows
                isRenderWithDefault
                defaultValues={defaultValues}
                userValue={userValue}
                setUserValue={setUserValue}
                isTouched={isTouched}
                setIsTouched={setIsTouched}
                disabled={!!formData.stepRequest || !subCategories?.length}
                onChange={([ dateFrom, dateTo ]) => {
                  handleSaveFormData({ dateFrom, dateTo })
                }}
              />
            </>
          ) : null}
          <div className="d-flex flex-wrap mt-3">
            <div className={classes.filtersBlock}>
              <div className="d-flex flex-column me-4">
                <span>Assets view</span>
                <div className={classnames('mt-1 flex-shrink-0', classes.connections)}>
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: !operateFilters.isShowChildren,
                    })}
                    checked={!operateFilters.isShowChildren}
                    label={<LegacyIcon name="wiz--other--blocks" width={14} height={14} size="2X" />}
                    title={intl.t('form.actions.showAssets')}
                    onChange={() => onOperateFiltersChange({ isShowChildren: false })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.isShowChildren,
                    })}
                    checked={operateFilters.isShowChildren}
                    label={<LegacyIcon name="wiz--other--blocks-children" width={14} height={14} size="2X" />}
                    title={intl.t('form.actions.showAssetsChildren')}
                    onChange={() => onOperateFiltersChange({ isShowChildren: true })}
                  />
                </div>
              </div>
              <div className="d-flex flex-column me-4">
                <span>Favorite</span>
                <div className={classnames('mt-1 flex-shrink-0', classes.connections)}>
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.isShowFavorites,
                    })}
                    checked={operateFilters.isShowFavorites}
                    label={(
                      <Icon
                        name="faStar"
                        size="md"
                        width={14}
                      />
)}
                    title={intl.t('form.actions.showAssets')}
                    onChange={() => onOperateFiltersChange({ isShowFavorites: !operateFilters.isShowFavorites })}
                  />
                </div>
              </div>
              <div className="d-flex flex-column me-4">
                <span>Board view</span>
                <div className={classnames('mt-1 flex-shrink-0', classes.connections)}>
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: !operateFilters.boardView || operateFilters.boardView === BOARD_VIEW_ENUM.GLOBAL,
                    })}
                    checked={operateFilters.boardView === BOARD_VIEW_ENUM.GLOBAL}
                    label={(
                      <Icon
                        name="faGlobe"
                        size="md"
                        width={14}
                      />
)}
                    title={intl.t('form.actions.showGlobal')}
                    onChange={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.GLOBAL })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL,
                    })}
                    checked={operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL}
                    label={(
                      <Icon
                        name="faPeopleGroup"
                        size="md"
                        width={14}
                      />
)}
                    title={intl.t('form.actions.showOrg')}
                    onChange={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.ORGANIZATIONAL })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL,
                    })}
                    checked={operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL}
                    label={(
                      <Icon
                        name="faUser"
                        size="md"
                        width={14}
                      />
)}
                    title={intl.t('form.actions.showPersonal')}
                    onChange={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.PERSONAL })}
                  />
                </div>
              </div>
              <div className="d-flex flex-column">
                <span>Severity</span>
                <div className={classnames('mt-1 flex-shrink-0', classes.connections)}>
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.severity === SEVERITY_ENUM.CRITICAL,
                    })}
                    checked={operateFilters.severity === SEVERITY_ENUM.CRITICAL}
                    label={(
                      <Icon
                        name="faTriangleExclamation"
                        size="md"
                        width={14}
                        color="#D71818"
                      />
)}
                    title={intl.t('form.actions.showCritical')}
                    onChange={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.CRITICAL })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.severity === SEVERITY_ENUM.WARNING,
                    })}
                    checked={operateFilters.severity === SEVERITY_ENUM.WARNING}
                    label={(
                      <Icon
                        name="faDiamondExclamation"
                        size="md"
                        width={14}
                        color="#fddd60"
                      />
)}
                    title={intl.t('form.actions.showWarning')}
                    onChange={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.WARNING })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: operateFilters.severity === SEVERITY_ENUM.SUCCESS,
                    })}
                    checked={operateFilters.severity === SEVERITY_ENUM.SUCCESS}
                    label={(
                      <Icon
                        name="faCircleCheck"
                        size="md"
                        width={14}
                        color="#7cffb2"
                      />
)}
                    title={intl.t('form.actions.showSuccess')}
                    onChange={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.SUCCESS })}
                  />
                  <FormCheckButton
                    className={classnames(classes.btn, {
                      [classes.active]: !operateFilters.severity || operateFilters.severity === SEVERITY_ENUM.ALL,
                    })}
                    checked={!operateFilters.severity || operateFilters.severity === SEVERITY_ENUM.ALL}
                    label={(
                      <Icon
                        name="faGrid2"
                        size="md"
                        width={14}
                      />
)}
                    title={intl.t('form.actions.showAll')}
                    onChange={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.ALL })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        placement="bottom-start"
        draggable
        autoclose
        options={dashboardTypes
          .filter(dashboardType => (grafanaCheckData?.isSuccess ? dashboardType : dashboardType.id !== 'grafana'))}
        onClick={handleAdd}
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      />
    </>
  )
}
const enhanceData = withObservables([], () => ({
  globalSettings: dbProvider.observeGlobalSettings([ 'EnvironmentName' ]),
}))

const enhanceTwinData = withObservables([ 'tree' ], ({ tree }) => ({
  twinsChain: tree ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', tree))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(([ item ]) => (item ? item.observeTwinsChain : of$([]))),
      map(items => items.reverse()),
    ) : of$([]),
}))

export default enhanceData(enhanceTwinData(Topbar))
