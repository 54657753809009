import {
  useState, useMemo, useContext, useEffect,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter, useLocationQuery } from '@/router'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import FormPipeline from '@/components/Forms/Pipeline'
import withTableActions from '@/containers/withTableActions'
import {
  VirtualTable, Pagination, withPagination, withSort,
} from '@wiz/components'
import { consts } from '@wiz/utils'
import ChartsDrawer from '@/components/ChartsDrawer'
import { wizataApi } from '@/api'
import EditButton from '@/shared/editButton'
import useAppContext from '@/hooks/useAppContext'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Pipeline from './pipeline'
import CellTemplate from './cellTemplate'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = props => (
  <>
    <VirtualTable className="flex-fill mb-3" {...props} />
    <Pagination {...props} />
  </>
)

const TableComponent = withSort({ localData: false })(
  withPagination({ localData: false })(Table),
)

const PipelinesTable = ({
  onEdit,
  onCreate,
  templateId,
  data,
  refetch,
  isLoading,
  error,
  isError,
  embed,
  ...props
}) => {
  const auth = useAuth()
  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()

  const {
    onRowSelect,
  } = useAppContext()

  const templates = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const columns = useMemo(() => {
    const next = [
      {
        accessor: 'id',
        disableResizing: true,
        disableSortBy: true,
        maxWidth: 30,
        className: 'justify-content-center text-nowrap',
        Cell: ({ cell }) => (auth.checkAccessUpdate('Pipeline') ? (
          <EditButton cell={cell} onEdit={onEdit} />
        ) : null),
      },
      {
        Header: intl.t('form.fields.key'),
        accessor: 'key',
        disableResizing: true,
        disableSortBy: true,
        minWidth: 200,
        Cell: ({ cell, row }) => (cell.value ? (
          <div className="min-w-0 text-truncate">
            <a
              className="text-truncate pointer"
              onClick={() => router.push({ query: { ...query, pipelineId: row.original.id } })}
              aria-hidden
            >
              {cell.value}
            </a>
          </div>
        ) : '-'),
      },
      {
        Header: intl.t('form.fields.template'),
        accessor: 'templateId',
        disableResizing: true,
        disableSortBy: true,
        minWidth: 80,
        Cell: ({ cell }) => (
          <CellTemplate id={cell.value} list={templates} />
        ),
      },
      {
        Header: intl.t('form.fields.updated'),
        accessor: 'updatedDate',
        disableResizing: true,
        disableSortBy: true,
        minWidth: 190,
        width: 190,
        maxWidth: 190,
        Cell: ({ cell, row }) => (
          <UpdatedInfo
            date={cell.value}
            userId={row.original.updatedById}
          />
        ),
      },
      {
        Header: intl.t('form.fields.created'),
        accessor: 'createdDate',
        disableResizing: true,
        disableSortBy: true,
        minWidth: 190,
        width: 190,
        maxWidth: 190,
        Cell: ({ cell, row }) => (
          <UpdatedInfo
            date={cell.value}
            userId={row.original.createdById}
          />
        ),
      },
    ]
    return next
  }, [ onEdit, intl, router, templates, auth, query ])

  if (isError) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('pipelines.loadingError')}</h4>
        <p className="text-danger">{error.message}</p>
        <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={refetch}
        >
          {intl.t('form.actions.refresh')}
        </button>
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
      <TableComponent
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data?.items || []}
        items={data?.items || []}
        total={data?.totalCount || 0}
        {...props}
        selection={auth.checkAccessManage('SectionPipelines')}
        onSelect={onRowSelect}
        loading={props.isLoading || props.isFetching}
      />
    </div>
  )
}

const Pipelines = ({
  open, onClose, templateId, messages, onStopListening, execution, filters, ...props
}) => {
  const [ editId, setEditId ] = useState(false)
  const [ isCreate, setCreate ] = useState(false)

  const { filters: contextFilters } = useAppContext()
  const { contextTwinId } = useGlobalExecute()

  const {
    pipelineId,
  } = useLocationQuery()
  const intl = useIntl()

  const list = useQuery({
    queryKey: [ 'pipelinesList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize
      const filtersArr = [
        {
          name: 'key',
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
        },
        ...(contextFilters.templateId ? [{
          name: 'templateId',
          type: contextFilters.templateId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: contextFilters.templateId === 'null' ? true : !contextFilters.templateId,
          ...(contextFilters.templateId && contextFilters.templateId !== 'null') ?
            { value: contextFilters.templateId } : {},
        }] : []),
      ]

      return wizataApi.pipelinesCore.getList({
        pagination: {
          take: limit - 1,
          skip: offset || 0,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: filtersArr.map(({
          name, value, values, operationType, type, canBeNull,
        }) => {
          if (!value && !values && !canBeNull) {
            return undefined
          }

          const next = {
            type,
            operationType,
            // start: 'string',
            // end: 'string',
            propertyName: name,
            canBeNull,
          }

          if (value) {
            next.value = value
          } else if (values?.length) {
            next.values = values
          }

          return {
            ...next,
          }
        }).filter(f => !!f),
      })
        .then((data) => {
          const { items } = data || {}
          const _items = items.slice(0, filters.pageSize)
          return { ...data, items: _items }
        })
    },
    // enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const templates = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleCloseDialog = () => {
    setEditId(null)
    onClose?.()
    setCreate(false)
  }

  const itemsList = useMemo(() => ({
    templateId: templates?.data,
  }), [ templates ])

  const drawerSequence = [
    {
      name: 'templateId',
      chart: consts.PanelChartType.pie,
    },
  ]

  const possibleFilters = useMemo(() => ({
    templateId: contextFilters.templateId,
  }), [ contextFilters ])

  useEffect(() => {
    if (list?.data) {
      list.refetch()
    }
  }, [
    filters.search,
    contextFilters.templateId,
    contextTwinId,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      {pipelineId ? (
        <Pipeline
          messages={messages}
          onStop={onStopListening}
          execution={execution}
          embed={props.embed}
        />
      ) : (
        <PipelinesTable
          onEdit={setEditId}
          onCreate={setCreate}
          templateId={templateId}
          filters={filters}
          {...props}
          {...list}
        />
      )}
      {pipelineId ? null : (
        <ChartsDrawer
          filters={possibleFilters}
          sequence={drawerSequence}
          entity="pipelines"
          itemsList={itemsList}
        />
      )}
      {open || isCreate || editId ? (
        <FormPipeline
          id={editId}
          templateId={templateId}
          pipelines={list.data}
          onClose={handleCloseDialog}
          onUpdate={list.refetch}
          dialog={{
            title: intl.t('pipelines.form.fields.title'),
            dataTestid: 'replacePipelineDialog',
          }}
        />
      ) : null}
    </div>
  )
}

export default withTableActions({
  initFilters (query) {
    return {
      templateId: query.templateId,
    }
  },
  defaultFilters () {
    return {
      sortBy: 'updatedDate',
      sortDir: 'desc',
    }
  },
})(
  Pipelines,
)
