import { useCallback, useState } from 'react'
import {
  useFormContext, useFieldArray,
} from 'react-hook-form'
import cx from 'classnames'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
// import FieldsInsights from './FieldsInsights'
import SectionInsightConfiguration from './SectionInsightConfiguration'
import SectionInsightConditions from './SectionInsightConditions'
import s from './index.module.css'

const SingleInsight = ({
  item, scopeRow, idx, remove,
}) => {
  const [ isExpanded, setExpanded ] = useState(false)

  const { contextTwinId } = useGlobalExecute()
  const queryClient = useQueryClient()

  const {
    mutate: deleteInsight,
  } = useMutation({
    mutationKey: [ 'deleteInsight', item.id ],
    mutationFn: () => wizataApi.insights.deleteById(item.id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      remove(idx)
      queryClient.invalidateQueries({ queryKey: [ 'insightsListByTwins', contextTwinId ] })
    },
  })

  const handleRemove = async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    deleteInsight()
  }

  const handleExpand = () => {
    setExpanded(!isExpanded)
  }
  return (
    <div className="d-flex">
      <div
        className={cx(s.accordion, {
          [s.active]: isExpanded,
        })}
      >
        <div
          className={s.accordionTitle}
          onClick={handleExpand}
          aria-hidden
        >
          {item.name}
        </div>
        {isExpanded ? (
          <div className={s.accordionBody}>
            <SectionInsightConfiguration scope={scopeRow} />
            <SectionInsightConditions scope={scopeRow} />
          </div>
        ) : null}
      </div>
      <button
        type="button"
        className={cx('btn btn-secondary-alt btn-sm ms-2', s.insightRemoveBtn)}
        onClick={handleRemove}
      >
        <Icon name="faTrash" size="md" />
      </button>
    </div>
  )
}

export default function SectionInsights ({ scope }) {
  const intl = useIntl()

  const { setValue } = useFormContext()

  const {
    fields, append, remove,
  } = useFieldArray({
    name: `${scope}.list`,
    keyName: '_id',
    shouldUnregister: true,
  })

  const handleAdd = useCallback(() => {
    const nextInsight = {
      id: `new_${fields.length}`, name: 'New Insight', sensorId: undefined, displayPrecision: undefined,
    }
    append(nextInsight)
    setValue(`${scope}.selectedId`, nextInsight.id, { shouldDirty: true })
  }, [ fields, append, scope, setValue ])

  return (
    <FormSection
      title={intl.t('insights.form.fields.insights')}
      description={intl.t('insights.form.fields.insightsDescr')}
      className="mb-2"
      classNameContent={cx('m-2', s.sectionConditionsContent)}
      addon={(
        <div className="d-flex ms-auto">
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-sm"
            onClick={handleAdd}
            disabled={fields.length >= 5}
          >
            <Icon name="faPlus" className="me-2" size="md" />
            {intl.t('insights.form.actions.addInsight')}
          </button>
        </div>
)}
    >
      {fields?.map((item, idx) => {
        const scopeRow = `${scope}.list.${idx}`

        if (!item) {
          return null
        }

        return (
          <SingleInsight
            key={item.id || item._id}
            scopeRow={scopeRow}
            item={item}
            idx={idx}
            remove={remove}
          />
        )
      })}
    </FormSection>
  )
}
