import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormInputNumber,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import s from './index.module.css'

export default function Fields ({
  scope,
}) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('insights.form.fields.title')}
        description={intl.t('insights.form.fields.titleDescr')}
        errors={get(errors, `${scope}.name`)}
        classNameLabel={s.semibold}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('insights.form.fields.sensor')}
        description={intl.t('insights.form.fields.sensorDescr')}
        errors={get(errors, `${scope}.sensorId`)}
        classNameLabel={s.semibold}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              // disabled={!id}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('insights.form.fields.value')}
        description={intl.t('insights.form.fields.valueDescr')}
        errors={get(errors, `${scope}.displayPrecision`)}
        classNameLabel={s.semibold}
      >
        <Controller
          name={`${scope}.displayPrecision`}
          render={({ field, fieldState }) => (
            <FormInputNumber
              {...field}
              invalid={fieldState.invalid}
              scale={8}
            />
          )}
        />
      </FormField>
    </>
  )
}
