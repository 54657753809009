import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import cx from 'classnames'
import { auth } from '@/auth'
import { appEnv } from '@/config'
import { useTheme } from '@/theme'
import { useIntl } from '@wiz/intl'
import { Skeleton } from '@wiz/components'
import DashboardView from '@/containers/Dashboards/View'
import Icon from '@/shared/icon'
import s from './index.module.css'
import GrafanaVariablesBar from './GrafanaVariablesBar'

const GRAFANA_BASE_URL = 'https://grafana-poc.onwizata.com/' // should be replaced by using /check
const DS_API_RECENT_URL = 'https://dev-wizard.onwizata.com'
const { hostname } = new URL(appEnv.DS_API_RECENT_URL || DS_API_RECENT_URL)
const themeByName = {
  sun: 'light',
  moon: 'dark',
}

const Grafana = ({
  content, formData, isEdit, check, dashboardId, type, variables = [],
}) => {
  const [ grafanaVar, setGrafanaVar ] = useState({})
  const [ showGrafanaLogin, setShowGrafanaLogin ] = useState(true)
  const [ token, setToken ] = useState()

  const refIframe = useRef(null)
  const refBef = useRef()
  const refTimeout = useRef()

  const intl = useIntl()
  const theme = useTheme()
  const _theme = themeByName[theme.name]

  const generateToken = async () => {
    const _token = await auth.acquireToken()
    setToken(_token)
  }

  useEffect(() => {
    generateToken()
  }, [])

  useEffect(() => {
    if (variables.length) {
      const defaultGrafanaVars = variables.reduce((acc, val) => {
        if (val?.type === 'select') {
          return val.defaults.reduce((acm, defVal) => {
            const propertyArr = Array.isArray(acm[val?.name]) ? [ ...acm[val?.name] ] : []
            return { ...acm, [val?.name]: [ ...propertyArr, `&var-${val?.name}=${defVal}`.replace(/\s/g, '%20') ] }
          }, acc)
        }
        const urlParam = `&var-${val?.name}=${val?.defaults?.[0]}`.replace(/\s/g, '%20')
        return { ...acc, [val?.name]: urlParam }
      }, {})
      setGrafanaVar(defaultGrafanaVars)
    }
  }, [ variables ])

  const grafanaURL = useMemo(() => {
    let url = ''

    if (content) {
      url += (check?.data || GRAFANA_BASE_URL) + content
    }
    url += '?'
    if (!isEdit) {
      url += 'kiosk&'
    }

    return url
  }, [ content, isEdit, check?.data ])

  const streamlitURL = useMemo(() => {
    let url = ''

    if (content) {
      url += content
    }
    url += `?dsapi=${hostname}&auth_token=${token}`

    return url
  }, [ content, token ])

  const varParams = variables.length ? Object.values(grafanaVar).join('').replace(/,/g, '') : ''
  const datesExtension = useMemo(() => {
    if (formData.dateFrom && formData.dateTo) {
      return `&from=${formData.dateFrom || 'now-24h'}&to=${formData.dateTo || 'now'}`
    }
    return ''
  }, [ formData ])
  const fullGrafanaUrl = `${grafanaURL}theme=${_theme}${varParams}${datesExtension}&_dash.hideTimePicker`

  const openGrafana = () => {
    const instance = window.open(fullGrafanaUrl)
    refTimeout.current = setTimeout(() => {
      instance.close()
      setShowGrafanaLogin(false)
    }, 1000)
  }

  // useEffect(() => {
  //   if (type === 'Grafana') {
  //     const checkGrafana = async () => {
  //       try {
  //         const res = await fetch(fullGrafanaUrl)
  //         console.log('response from fetch:', res)
  //         setShowGrafanaLogin(true)
  //       } catch (err) {
  //         // console.error('fetch error: ', err)
  //         openGrafana()
  //         // setShowGrafanaLogin(true)
  //       }
  //     }

  //     checkGrafana()
  //   }

  //   return () => clearTimeout(refTimeout.current)
  // }, [])

  // useEffect(() => {
  //   function checkIframeLoaded () {
  //     // Get a handle to the iframe element
  //     const iframeDoc = refIframe.current?.contentDocument || refIframe.current?.contentWindow.document
  //     console.log('ready?', refIframe.current?.readyState)
  //     console.log('iframeDoc?', iframeDoc)
  //     // Check if loading is complete
  //     if (refIframe.current?.readyState == 'complete') {
  //       // iframe.contentWindow.alert("Hello");
  //       refIframe.current.contentWindow.onload = function () {
  //         alert('I am loaded')
  //       }
  //       // The loading is complete, call the function we want executed once the iframe is loaded
  //       return
  //     }

  //     // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
  //     // window.setTimeout(checkIframeLoaded, 100)
  //     console.log('look like not loaded')
  //   }
  //   if (refIframe.current) {
  //     checkIframeLoaded()
  //   }
  // }, [])

  if (type === 'Dashboard') {
    return (
      <div className={cx('d-flex position-relative', s.wrap)}>
        <div ref={refBef} className="d-flex flex-column w-100">
          <DashboardView
            match={{ params: { id: dashboardId } }}
            embed
            embedWidth={refBef.current?.offsetWidth}
            rightbar={isEdit}
            isEditable={isEdit}
          />
        </div>
      </div>
    )
  }

  if (type === 'Iframe') {
    return (
      <iframe
        title="iframe component"
        className={s.twin}
        src={content}
        frameBorder="0"
      />
    )
  }

  if (type === 'Streamlit') {
    return (
      <iframe
        title="iframe component"
        className={s.twin}
        src={streamlitURL}
        frameBorder="0"
      />
    )
  }

  return (check?.isLoading && check?.isFetching) ? <Skeleton width="100%" height="100%" /> : (
    <>
      {showGrafanaLogin ? (
        <div className="mt-2 d-flex align-items-center ms-4">
          {/* {intl.t('grafana.openTitle')} */}
          If you cannot see grafana dashboard below, please click on Open button
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text ms-2"
            onClick={openGrafana}
          >
            Open
          </button>
          <Icon name="faXmark" onClick={() => setShowGrafanaLogin(false)} className="pointer ms-1" />
        </div>
      ) : null}
      {/* {variables.length && !isEdit ? (
        <GrafanaVariablesBar
          variables={variables}
          grafanaVar={grafanaVar}
          updateParams={setGrafanaVar}
        />
      ) :
        null} */}
      <iframe
        ref={refIframe}
        title="grafana"
        className={s.twin}
        src={fullGrafanaUrl}
        frameBorder="0"
      />
    </>
  )
}

export default Grafana
