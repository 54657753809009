import {
  useState, useRef, useEffect, useCallback, useImperativeHandle, useContext,
} from 'react'
import cx from 'classnames'
import {
  DragProvider,
  FormInputSearch,
  useDebounce,
} from '@wiz/components'
import { useRouter } from '@/router'
import { useIntl } from '@wiz/intl'
import TwinSelectorTree from '@/hoc/TwinSelectorTree'
import FormTwinTree from '@/components/Form/Tree/Tree'
import { isMobile } from '@wiz/utils'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Icon from '@/shared/icon'

import TwinTreeContent from './TwinTreeContent'

const DataTwinTree = TwinSelectorTree(FormTwinTree)

const TwinList = ({
  onClick, scrollTo, defaultSelected, onMove, refDropdown,
}) => {
  const [ search, setSearch ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const { contextTwinId, handleTwinChange } = useGlobalExecute()

  useEffect(() => {
    if (search) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [ search ])

  const refSearch = useRef()
  const refDrag = useRef()
  const refTree = useRef()
  const refScroll = useRef()
  const intl = useIntl()
  const router = useRouter()
  const wait = useDebounce(300) // open dialog delay

  useImperativeHandle(refScroll, () => ({
    scrollTo (id) {
      refTree.current?.scrollTo(id)
    },
  }), [])

  const handleResetFilter = useCallback(() => {
    refSearch.current.reset()
  }, [])

  const handleDragStart = useCallback((data) => {
    refDrag.current.data.add(data)
  }, [])

  const handleCollapseExpandAll = useCallback(() => {
    setIsOpen(!isOpen)
  }, [ isOpen ])

  const handleActive = useCallback(data => data.recordId === (contextTwinId), [ contextTwinId ])

  useEffect(() => {
    if (!wait && scrollTo && refTree.current) {
      refTree.current?.scrollTo(scrollTo)
    }
  }, [
    wait,
    defaultSelected,
    scrollTo,
  ])

  return (
    <>
      <div className={cx('d-flex align-items-center', { 'mt-3': isMobile })}>
        <div className="mb-2 col-md-3 flex-fill">
          <FormInputSearch
            ref={refSearch}
            autoFocus
            className="form-control-sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />
        </div>
        <div className="mb-2 d-flex col-md-auto ms-2">
          <button
            title={intl.t('form.actions.collapseExpandAll')}
            type="button"
            className="btn btn-fill-secondary-alt"
            disabled={wait || !contextTwinId}
            onClick={() => {
              handleTwinChange(null, true)
              router.push({ query: {} })
              refDropdown.current?.close()
            }}
          >
            <Icon name="faRotateRight" className="me-2" />
            {intl.t('Reset')}
          </button>
          <button
            title={intl.t('form.actions.collapseExpandAll')}
            type="button"
            className="btn btn-fill-secondary-alt ms-2"
            disabled={wait}
            onClick={handleCollapseExpandAll}
          >
            {intl.t(isOpen ? 'Collapse' : 'Expand')}
          </button>
        </div>
      </div>
      { wait ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <Icon name="faSpinner" size="lg" spin />
        </div>
      ) : (
        <div className="flex-fill d-flex flex-column" style={{ height: 'calc(100vh - 290px)' }}>
          <div className="flex-fill position-relative">
            <DragProvider
              ref={refDrag}
              name="internal"
            >
              <DataTwinTree
                ref={refTree}
                className="position-absolute-fill"
                Content={TwinTreeContent}
                value={contextTwinId}
                active={handleActive}
                search={search}
                withSensors={false}
                draggable="internal"
                onChange={onClick}
                onDrop={onMove}
                onDragStart={handleDragStart}
                isOpenByDefault={isOpen}
                isActiveTwinOpen={!isOpen}
              />
            </DragProvider>
          </div>
        </div>
      ) }
    </>
  )
}

export default TwinList
