export const severities = [
  { id: 3, value: 'info', color: '#F6F4FB' },
  { id: 2, value: 'success', color: '#7CFFB2' },
  { id: 1, value: 'warning', color: '#FDDD60' },
  { id: 0, value: 'critical', color: '#D71818' },
]

export const conditionTypes = [
  'eq',
  'neq',
  'lt',
  'lte',
  'gt',
  'gte',
]
