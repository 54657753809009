import {
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import queryString from 'query-string'
import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import { useIntl } from '@wiz/intl'
import { CustomScrollbars } from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import FormGrafanaDashboards from '@/components/Forms/GrafanaDashboards'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAppContext from '@/hooks/useAppContext'
import Grafana from './grafana'
import TilesView from './components/tiles'
import classes from './index.module.css'

const GrafanaDashboards = () => {
  const router = useRouter()
  const {
    category, sub, view, exact, tree,
  } = useLocationQuery()
  const {
    formData,
    isEditing,
    handleEdit,
    handleAdd,
    grafanaCheckData,
    dashboardsComponentsDeepList,
    currentTwinDashboards,
    handleDashboardCreate,
    labels,
    isCreating,
    editId,
    handleEditId,
  } = useAppContext()
  const {
    selectedComponent, setSelectedComponent, contextTwinId, handleTwinChange,
  } = useGlobalExecute()

  const intl = useIntl()

  const options = useMemo(() => {
    if (!dashboardsComponentsDeepList?.data) {
      return []
    }

    return dashboardsComponentsDeepList?.data.map(d => ({ ...d, value: d.id, label: d.name }))
  }, [ dashboardsComponentsDeepList?.data ])

  const dashboard = useMemo(() => {
    if (!options?.length || !category || category === 'search') {
      return null
    }

    const item = options.find(i => i.labelId === category && i.id === sub)

    if (!item) {
      return options[0]
    }

    return item
  }, [ options, category, sub ])

  const isOwn = useMemo(() => {
    if (dashboardsComponentsDeepList?.data?.length) {
      const twin = dashboardsComponentsDeepList?.data?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ dashboardsComponentsDeepList, contextTwinId ])

  const categories = useMemo(
    () => dashboardsComponentsDeepList?.data?.reduce((acc, val) => {
      if (!contextTwinId || !isOwn) {
        return []
      }
      const label = labels?.find(l => l.id === val.labelId)

      const subCat = acc.find(item => item.labelId === val.labelId)

      if (!subCat) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: camelCase(label?.icon),
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ dashboardsComponentsDeepList?.data, labels, contextTwinId, isOwn ],
  )

  const subCategories = useMemo(
    () => dashboardsComponentsDeepList?.data?.reduce((acc, val) => {
      if (!contextTwinId || !isOwn) {
        return []
      }
      const subCat = acc.find(item => item.id === val.id)
      if (!subCat && val.labelId === category) {
        acc.push({ ...val, label: val.name, value: val.id })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ dashboardsComponentsDeepList?.data, category, contextTwinId, isOwn ],
  )

  const handleBackButton = useCallback((event) => {
    const query = queryString.parse(event.target.location.search)
    if (!query.tree && contextTwinId) {
    // window.history.pushState(null, document.title, window.location.href);
      handleTwinChange(null)
    }
  }, [ contextTwinId, handleTwinChange ])

  useEffect(() => {
    window.addEventListener('popstate', handleBackButton)
    return () => window.removeEventListener('popstate', handleBackButton)
  }, [ tree, contextTwinId ])

  useEffect(() => {
    if (contextTwinId && !sub && categories?.length && !!category && category !== 'search') {
      router.replace({
        query: {
          category: categories[0]?.labelId, sub: subCategories[0]?.id, contextTwinId,
        },
      })
    }
  }, [ sub, contextTwinId, categories, subCategories, router ])

  useEffect(() => {
    if (dashboardsComponentsDeepList?.data) {
      const hasDashboards = dashboardsComponentsDeepList.data.filter(ds => ds.twinId === contextTwinId)
      const foundMatch = dashboardsComponentsDeepList.data
        .find(option => option.name === selectedComponent.dashboard?.name &&
          option.labelId === selectedComponent.dashboard?.labelId)
      if (hasDashboards?.length) {
        if (!category && sub && contextTwinId && selectedComponent?.dashboard) {
          if (foundMatch) {
            router.replace({
              query: {
                category: foundMatch.labelId,
                sub: foundMatch.id,
                view,
              },
            })
            setSelectedComponent(prev => ({ ...prev, dashboard: foundMatch }))
          }
        } else if (category && category !== 'search' && sub && !exact) {
          const foundSameDashboard = categories.find(cat => cat.labelId === category && cat.twinId === contextTwinId)
          if (selectedComponent && foundSameDashboard) {
            if (foundMatch) {
              router.replace({
                query: {
                  category: foundMatch.labelId,
                  sub: foundMatch.id,
                  view,
                },
              })
              setSelectedComponent(prev => ({ ...prev, dashboard: foundMatch }))
            } else {
              router.replace({
                query: {
                  category: foundSameDashboard.labelId, sub: foundSameDashboard.id, view,
                },
              })
              setSelectedComponent(prev => (
                {
                  ...prev,
                  category: foundSameDashboard.labelId,
                  dashboard: foundSameDashboard,
                }))
            }
          } else {
            router.replace({
              query: {
                category: 'search',
              },
            })
          }
        }
      } else {
        router.replace({
          query: {
            category: 'search',
          },
        })
      }
    }
  }, [ contextTwinId, dashboardsComponentsDeepList?.data ])

  return (
    <CustomScrollbars
      // autoHeight
      horizontal={false}
      shadow={false}
      autoHide
    >
      <div className={cx(
        'd-flex flex-column flex-fill position-relative h-100',
        { [classes.wrap]: !!isEditing },
      )}
      >
        {dashboard ? (
          <Grafana
            {...dashboard}
            formData={formData}
            // variables={grafanaVariables.data}
            isEdit={isEditing}
            onEditing={handleEdit}
            check={grafanaCheckData}
          />
        ) : (
          <TilesView
            twinId={contextTwinId}
            components={contextTwinId ? dashboardsComponentsDeepList : currentTwinDashboards}
            deepList={dashboardsComponentsDeepList}
            list={currentTwinDashboards}
          />
        )}
      </div>
      {(isCreating || editId) ? (
        <FormGrafanaDashboards
          scope={isCreating || editId?.type?.toLowerCase()}
          item={editId}
          onClose={() => {
            handleAdd(false)
            handleEditId(null)
          }}
          items={subCategories}
          onSuccess={handleDashboardCreate}
          onUpdate={dashboardsComponentsDeepList.refetch}
          dialog={{
            title: isCreating ? intl.t(`components.form.create.${isCreating}`) :
              intl.t(`components.form.edit.${editId?.type?.toLowerCase()}`),
            dataTestid: 'createComponentDialog',
          }}
        />
      ) : null}
    </CustomScrollbars>
  )
}

export default GrafanaDashboards
